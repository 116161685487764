import { useEffect } from 'react'

import getDomainBranding from 'lib/domain-branding'

export const DomainBranding = () => {
  useEffect(() => {
    const favicon = document.querySelector('link#favicon') as HTMLLinkElement

    if (!favicon) return

    favicon.href = getDomainBranding().favicon
  }, [])

  useEffect(() => {
    // SEE: https://github.com/assuranceiq/insurance/blob/f81dc3bc8705d3764dd28125bced38f7bfa5c741/app/components/turbo_plates/turbo_plate_component.rb#L29
    document.title = `${getDomainBranding().name} - Get a Quote`
  }, [])

  return null
}
