import { Input } from 'components/SimpleForm/Input'
import { SimpleFormComponentProps, ZipConfig } from 'types/simple-form-components/input-components'

const ZIP_CODE_REGEX = '^\\d{5}$'
const ZIP_CODE_MASK = '00000'

export type ZipProps = SimpleFormComponentProps<ZipConfig>

export const Zip = ({
  mask_options,
  placeholder = 'Enter Zip',
  validation,
  attrs,
  ...inputProps
}: ZipProps) => {
  return (
    <Input
      {...inputProps}
      placeholder={placeholder}
      attrs={{
        ...attrs,
        inputMode: 'numeric',
        autoComplete: 'postal-code',
        maxLength: 5,
      }}
      validation={{
        required: 'Please enter a Zip Code',
        pattern: { value: ZIP_CODE_REGEX, message: 'Zip code must be 5 digits' },
        ...validation,
      }}
      mask_options={{ mask: ZIP_CODE_MASK, ...mask_options }}
    />
  )
}
