import { useEffect } from 'react'

import Button from '@mui/material/Button'
import CardActions from '@mui/material/CardActions'
import type { CardActionsProps } from '@mui/material/CardActions'

import clsx from 'clsx'

import { BackButton } from 'components/BackButton/BackButton'
import { NextButton } from 'components/SimpleForm/NextButton'
import { SkipButton } from 'components/SkipButton/SkipButton'
import { useDeviceType } from 'hooks/useDeviceType'
import { useStoreActions, useStoreState } from 'lib/store'
import { questionID } from 'plates/SimpleForm'
import { CallInfoMetadata, PlateConfig } from 'types/plate'
import { callInfoCSI } from 'utils/extract-data-for-csi'

import styles from './PlatesBottomNavigation.module.scss'

/**
 * Hook to set the bottom navigation state to false when the component unmounts, it resolves the bug when modal closed over dental flow
 * and the SimpleForm continue button is shown. Reason for the bug is that when we set navigation state to false in cleanup function here,
 * it will be set after modal state will be updated (it's nested component of the modal), so we couldn't know if we in the modal or not.
 */
export const useCleanUpPlatesBottomNavigation = () => {
  const setPlatesBottomNavigation = useStoreActions(
    actions => actions.current.setPlatesBottomNavigation,
  )

  useEffect(
    () => () => setPlatesBottomNavigation(false),

    [setPlatesBottomNavigation],
  )
}

export const PlatesBottomNavigation = ({ className, ...cardActionProps }: CardActionsProps) => {
  const isModalPresenting = useStoreState(state => state.current.modalPlatesPresenting)
  const plate = useStoreState(state =>
    isModalPresenting ? state.current.modal.plate : state.current.plate,
  )
  const isPending = useStoreState(state => state.plate.isPending)
  const context_data = useStoreState(state => state.current.context_data)

  const setPlatesBottomNavigation = useStoreActions(
    actions => actions.current.setPlatesBottomNavigation,
  )
  const setOutsideFormSubmitting = useStoreActions(state => state.current.setOutsideFormSubmitting)

  useEffect(() => {
    setPlatesBottomNavigation(true)
  }, [setPlatesBottomNavigation])

  const { isMobile } = useDeviceType()
  const isSimpleForm = plate?.component === 'SimpleForm'

  const callInfoPlate =
    plate?.component === 'CallInfo' ? (plate as PlateConfig<'CallInfo'>) : undefined
  const phone = callInfoPlate?.component_options?.plate_body?.phone

  const showCallNowButton = isMobile && phone
  const showNextButton = isSimpleForm && !showCallNowButton

  const metadata: CallInfoMetadata = {
    direct_lead_id: context_data?.direct_lead_id,
    lead_id: context_data?.lead_id,
  }
  const callCSIPayload = callInfoCSI(metadata)

  return (
    <CardActions className={clsx(className, styles.root)} {...cardActionProps}>
      <BackButton text='Back' aria-label='Go to previous plate' className={styles.backBtn} />
      {/* TODO: Will be removed in https://assuranceiq.atlassian.net/browse/GR-119 */}
      {showCallNowButton && (
        <Button
          variant='contained'
          href={`tel:${phone}`}
          className={styles.callBtn}
          data-csi-record='apply_now'
          data-csi-payload={JSON.stringify(callCSIPayload)}
        >
          Call now
        </Button>
      )}
      {plate?.component_options?.skip_button?.field && (
        <SkipButton
          questionID={questionID}
          config={plate.component_options.skip_button}
          endIcon={null}
          className={styles.skipBtn}
        />
      )}
      {showNextButton && (
        <NextButton
          isPending={isPending}
          nextButtonConfig={plate?.component_options?.next_button}
          buttonProps={{
            onClick: () => setOutsideFormSubmitting(true),
            className: styles.nextBtn,
            'aria-describedby': questionID,
            'aria-disabled': isPending,
          }}
        >
          Next
        </NextButton>
      )}
    </CardActions>
  )
}
