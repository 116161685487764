import { useEffect, useRef } from 'react'

import { SCHEDULING_HASH } from 'components/CallSchedulingModal'
import { useStoreActions, useStoreState } from 'lib/store'

import { urlFromAPIFormat, urlToAPIFormat } from '../../utils/url-formatting'

export function VirtualRouter() {
  const triggeredByHistoryPopState = useRef<boolean>(false)
  const url = useStoreState(state => state.current.plate?.url)
  const replace_history = useStoreState(
    state => state.current.plate?.component_options?.replace_history,
  )
  const component = useStoreState(state => state.current.plate?.component)
  const get = useStoreActions(actions => actions.plate.get)
  const dlToken = useStoreState(state => state.current.context_data?.dl_token)

  useEffect(() => {
    const onPopState = () => {
      // Ignore popstate if we are on scheduling modal
      if (window.location.hash === SCHEDULING_HASH) return

      triggeredByHistoryPopState.current = true
      get(urlToAPIFormat(window.location.pathname))
    }
    window.addEventListener('popstate', onPopState)

    return () => window.removeEventListener('popstate', onPopState)
  }, [get])

  useEffect(() => {
    // do not perform pushState for redirect plates - this breaks redirect functionality
    if (!url || component === 'Redirect') return

    // Ignore url changes from back/forward history
    if (triggeredByHistoryPopState.current) {
      triggeredByHistoryPopState.current = false
      return
    }
    const searchParams = new URLSearchParams(window.location.search)
    ;['format', 'token'].forEach(param => searchParams.delete(param))

    if (dlToken) {
      // If DL token is present - then this is a webview. In this case we need to add the dlToken
      // into the url to preserve the session if user will choose "open in browser" from the webview
      searchParams.set('token', dlToken)
    }

    const queryParams = searchParams.toString().length === 0 ? '' : `?${searchParams.toString()}`
    /*
     Depending on plate settings - add new history record or replace previous one
     For example you have loading plate redirecting to results - if you won't remove loading plate form history
     then pressing browser's "Back" button will go back to loading plate which will imeediately redirect back to results
     */
    const historyFn =
      replace_history === true ? window.history.replaceState : window.history.pushState
    historyFn.bind(window.history)(
      {},
      '',
      urlFromAPIFormat(url) + queryParams + window.location.hash,
    )
  }, [url, dlToken, component, replace_history])

  return null
}
