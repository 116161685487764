import { SvgIcon, SvgIconProps } from 'icons'

export const CalendarIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox='0 0 16 16'>
      <path d='M4.56522 7H3.91304C3.55286 7 3.26087 7.29848 3.26087 7.66667C3.26087 8.03486 3.55286 8.33333 3.91304 8.33333H4.56522C4.9254 8.33333 5.21739 8.03486 5.21739 7.66667C5.21739 7.29848 4.9254 7 4.56522 7Z' />
      <path d='M7.82609 7H7.17391C6.81373 7 6.52174 7.29848 6.52174 7.66667C6.52174 8.03486 6.81373 8.33333 7.17391 8.33333H7.82609C8.18627 8.33333 8.47826 8.03486 8.47826 7.66667C8.47826 7.29848 8.18627 7 7.82609 7Z' />
      <path d='M11.087 7H10.4348C10.0746 7 9.78261 7.29848 9.78261 7.66667C9.78261 8.03486 10.0746 8.33333 10.4348 8.33333H11.087C11.4471 8.33333 11.7391 8.03486 11.7391 7.66667C11.7391 7.29848 11.4471 7 11.087 7Z' />
      <path d='M4.56522 9.66667H3.91304C3.55286 9.66667 3.26087 9.96514 3.26087 10.3333C3.26087 10.7015 3.55286 11 3.91304 11H4.56522C4.9254 11 5.21739 10.7015 5.21739 10.3333C5.21739 9.96514 4.9254 9.66667 4.56522 9.66667Z' />
      <path d='M7.82609 9.66667H7.17391C6.81373 9.66667 6.52174 9.96514 6.52174 10.3333C6.52174 10.7015 6.81373 11 7.17391 11H7.82609C8.18627 11 8.47826 10.7015 8.47826 10.3333C8.47826 9.96514 8.18627 9.66667 7.82609 9.66667Z' />
      <path d='M11.087 9.66667H10.4348C10.0746 9.66667 9.78261 9.96514 9.78261 10.3333C9.78261 10.7015 10.0746 11 10.4348 11H11.087C11.4471 11 11.7391 10.7015 11.7391 10.3333C11.7391 9.96514 11.4471 9.66667 11.087 9.66667Z' />
      <path d='M4.56522 12.3333H3.91304C3.55286 12.3333 3.26087 12.6318 3.26087 13C3.26087 13.3682 3.55286 13.6667 3.91304 13.6667H4.56522C4.9254 13.6667 5.21739 13.3682 5.21739 13C5.21739 12.6318 4.9254 12.3333 4.56522 12.3333Z' />
      <path d='M7.82609 12.3333H7.17391C6.81373 12.3333 6.52174 12.6318 6.52174 13C6.52174 13.3682 6.81373 13.6667 7.17391 13.6667H7.82609C8.18627 13.6667 8.47826 13.3682 8.47826 13C8.47826 12.6318 8.18627 12.3333 7.82609 12.3333Z' />
      <path d='M11.087 12.3333H10.4348C10.0746 12.3333 9.78261 12.6318 9.78261 13C9.78261 13.3682 10.0746 13.6667 10.4348 13.6667H11.087C11.4471 13.6667 11.7391 13.3682 11.7391 13C11.7391 12.6318 11.4471 12.3333 11.087 12.3333Z' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.9022 2H13.6957C14.416 2 15 2.59695 15 3.33333V14.6667C15 15.403 14.416 16 13.6957 16H1.30435C0.583976 16 0 15.403 0 14.6667V3.33333C0 2.59695 0.583976 2 1.30435 2H2.28261C2.4627 2 2.6087 2.14924 2.6087 2.33333V3.83333C2.6087 4.10948 2.82769 4.33333 3.09783 4.33333C3.36797 4.33333 3.58696 4.10948 3.58696 3.83333V0.666667C3.58696 0.298477 3.87894 0 4.23913 0C4.59932 0 4.8913 0.298477 4.8913 0.666667V1.834C4.89166 1.92579 4.96456 2 5.05435 2H9.13043C9.31053 2 9.45652 2.14924 9.45652 2.33333V3.83333C9.45652 4.10948 9.67551 4.33333 9.94565 4.33333C10.2158 4.33333 10.4348 4.10948 10.4348 3.83333V0.666667C10.4348 0.298477 10.7268 0 11.087 0C11.4471 0 11.7391 0.298477 11.7391 0.666667V1.83333C11.7391 1.92538 11.8121 2 11.9022 2ZM13.3696 14.6667C13.5497 14.6667 13.6957 14.5174 13.6957 14.3333V6.33333C13.6957 6.14924 13.5497 6 13.3696 6H1.63043C1.45034 6 1.30435 6.14924 1.30435 6.33333V14.3333C1.30435 14.5174 1.45034 14.6667 1.63043 14.6667H13.3696Z'
      />
    </SvgIcon>
  )
}
