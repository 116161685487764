import { useEffect } from 'react'

import { useStoreState } from 'lib/store'
import { addFeatureFlagEvaluation, setGlobalContextProperty } from 'utils/datadog'

export function DataDogGlobalContext() {
  const { path, plate, context_data } = useStoreState(state => state.current)
  const tests = useStoreState(state => state.plate.data?.context?.service_data?.tests)

  useEffect(() => {
    if (!plate || !context_data || !path) return

    const data = {
      current_user_id: context_data.current_user_id,
      direct_lead_id: context_data.direct_lead_id,
      lead_id: context_data.lead_id,

      line_of_business: plate.line_of_business,

      // workaround for DataDog limitations - as of Feb 2021 it is impossible to create DD dashboard
      // widgets that would group DD RUM events on multiple dimensions
      lob_salespath_platename: [plate.line_of_business, plate.sales_path, plate.name].join(':'),

      plate: {
        sales_path: plate.sales_path,
        path_name: path.name,
        plate_group_name: plate.funnel_stage,
        plate_name: plate.name,
      },

      // user details
      connection: {
        // @ts-ignore experimental API
        type: navigator.connection ? navigator.connection.type : 'unknown',
        effectiveType: navigator.connection
          ? // @ts-ignore experimental API
            navigator.connection.effectiveType
          : 'unknown',
        // @ts-ignore experimental API
        downlink: navigator.connection ? navigator.connection.downlink : 'unknown',
        // @ts-ignore experimental API
        rtt: navigator.connection ? navigator.connection.rtt : 'unknown',
      },
      // @ts-ignore experimental API
    } as Record<string, unknown>
    Object.keys(data).forEach(key => {
      if (data[key]) {
        setGlobalContextProperty(key, data[key])
      }
    })
  }, [plate, path, context_data])

  useEffect(() => {
    if (tests) {
      Object.keys(tests).forEach(key => addFeatureFlagEvaluation(key, tests[key]))
    }
  }, [tests])

  return null
}
