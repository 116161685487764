import { useCallback, useEffect, useState } from 'react'

import { useLoad3rdPartyScript } from 'hooks/useLoad3rdPartyScript'
import { platesAPI } from 'lib/platesAPI/platesAPI'
import { useStoreState } from 'lib/store'
import { RECAPTCHA_ENTERPRISE_SITEKEY } from 'utils/environment-variables'

type ClientData = {
  screen_height: number
  screen_width: number
  referrer: string
  browser_timezone_offset: number
  timezone_identifier: string
  is_iframe: boolean
  iframe_referrer: string
  universal_lead_id?: string
  ga_client_id?: string
  trusted_form_token?: string
  trusted_form_cert_url?: string
  trusted_form_ping_url?: string
  disclosure_banner_display_time?: string
  recaptcha_token?: string
  csi_id?: string
}

interface UpdateClientDataResponse {
  error?: string
}

const LEAD_ID_ELEMENT_ID = 'leadid_token'
let gaTimeoutId: NodeJS.Timeout | undefined
let csiTimeoutId: NodeJS.Timeout | undefined
let recaptchaTimeoutId: NodeJS.Timeout | undefined
export const CSI_ID_KEY = 'assurance-csi-client-id'

let prevData = ''
// send client data on the backend
const postClientData = (
  dataToSend: ClientData,
  authenticity_token: string,
  dlToken: string | undefined,
) => {
  // compare data to previously sent to avoid duplicate requests
  // do not include authenticity_token into comparison because it changes on every plate submit
  const newData = JSON.stringify(dataToSend)
  if (prevData === newData) {
    return
  }
  prevData = newData

  platesAPI
    .post<UpdateClientDataResponse>('/tp/update_client_data', {
      ...dataToSend,
      authenticity_token,
      token: dlToken,
    })
    .then(response => response.data.error)
    .catch(e => console.error(e))
}

const getGAClientId = () => {
  try {
    // @ts-ignore
    return window.ga.getAll()[0].get('clientId')
  } catch (e) {
    return
  }
}

const getCSIId = () => {
  return window.localStorage?.getItem(CSI_ID_KEY)
}

// helper function to get HTML Element
function getElementValue(elementId: string) {
  return document.getElementById(elementId)?.getAttribute('value') ?? ''
}

export const UpdateClient = () => {
  const disclosureBannerDisplayed = useStoreState(state => state.current.disclosureBannerDisplayed)

  const [clientData, setClientData] = useState<ClientData>(() => {
    const clientData: ClientData = {
      screen_height: window.screen.availHeight,
      screen_width: window.screen.availWidth,
      referrer: document.referrer,
      browser_timezone_offset: new Date().getTimezoneOffset(),
      timezone_identifier: Intl.DateTimeFormat().resolvedOptions().timeZone,
      is_iframe: window.self !== window.top,
      iframe_referrer: document.referrer,
      disclosure_banner_display_time: disclosureBannerDisplayed,
    }
    const ga_client_id = getGAClientId()
    if (ga_client_id) {
      clientData.ga_client_id = ga_client_id
    }
    const csi_id = getCSIId()
    if (csi_id) {
      clientData.csi_id = csi_id
    }

    const leadIdObserverTarget = document.getElementById(LEAD_ID_ELEMENT_ID) as HTMLInputElement
    if (leadIdObserverTarget && leadIdObserverTarget.value) {
      clientData['universal_lead_id'] = getElementValue(LEAD_ID_ELEMENT_ID)
    }

    const trustedFormTokenElement = document.getElementById(
      'xxTrustedFormToken_0',
    ) as HTMLInputElement
    if (trustedFormTokenElement && trustedFormTokenElement.value) {
      clientData['trusted_form_token'] = getElementValue('xxTrustedFormToken_0')
      clientData['trusted_form_cert_url'] = getElementValue('xxTrustedFormCertUrl_0')
      clientData['trusted_form_ping_url'] = getElementValue('xxTrustedFormPingUrl_0')
    }

    return clientData
  })

  const authenticity_token = useStoreState(state => state.plate.data?.session?.authenticity_token)
  const dlToken = useStoreState(state => state.current.context_data?.dl_token)

  const pollGAClientData = useCallback(() => {
    const ga_client_id = getGAClientId()
    if (ga_client_id) {
      setClientData(c => {
        return {
          ...c,
          ga_client_id,
        }
      })
    } else {
      gaTimeoutId = setTimeout(pollGAClientData, 1000)
    }
  }, [])

  // check if the ga_client_id is set, if not, try to get it again
  // this is needed because the ga_client_id is not available on the first render
  // and we need to wait for the ga_client_id to be available before sending the client data
  useEffect(() => {
    if (!clientData.ga_client_id) {
      pollGAClientData()
      return () => clearTimeout(gaTimeoutId)
    }
  }, [clientData, pollGAClientData])

  const pollCSI = useCallback(() => {
    const csi_id = getCSIId()
    if (csi_id) {
      setClientData(c => {
        return {
          ...c,
          csi_id,
        }
      })
    } else {
      csiTimeoutId = setTimeout(pollCSI, 1000)
    }
  }, [])

  // check if the csi_id is set, if not, try to get it again
  // this is needed because csi_id may not be available on page load and will be set later when snippet loads
  useEffect(() => {
    if (!clientData.csi_id) {
      pollCSI()
      return () => clearTimeout(csiTimeoutId)
    }
  }, [clientData, pollCSI])

  // reCaptcha Enterprise token
  const pathName = useStoreState(state => state.plate.data?.context?.path?.name)
  const updateRecaptcha = useStoreState(
    state => state.plate.data?.context?.plate?.component_options?.update_recaptcha,
  )

  // Append recaptcha script and delay init to let page initialize faster
  const { appended: recaptchaAppended } = useLoad3rdPartyScript({
    id: 'google-recaptcha',
    src: `https://www.google.com/recaptcha/enterprise.js?render=${RECAPTCHA_ENTERPRISE_SITEKEY}`,
    loadIf: () => {
      return !!RECAPTCHA_ENTERPRISE_SITEKEY
    },
  })

  useEffect(() => {
    if (!recaptchaAppended && !updateRecaptcha) return

    // Waiting for the reCaptcha Enterprise token to become available
    const pollRecaptchaToken = () => {
      // @ts-ignore
      if (window.grecaptcha?.enterprise?.ready) {
        // @ts-ignore
        window.grecaptcha.enterprise.ready(async () => {
          // @ts-ignore
          const token = await window.grecaptcha.enterprise.execute(RECAPTCHA_ENTERPRISE_SITEKEY, {
            action: pathName || 'N/A',
          })

          setClientData(c => {
            return {
              ...c,
              recaptcha_token: token,
            }
          })
        })
      } else {
        recaptchaTimeoutId = setTimeout(pollRecaptchaToken, 1000)
      }
    }

    pollRecaptchaToken()

    return () => clearTimeout(recaptchaTimeoutId)
  }, [pathName, updateRecaptcha, recaptchaAppended])

  useEffect(() => {
    if (!authenticity_token) return
    if (
      !clientData['universal_lead_id'] &&
      !clientData['trusted_form_token'] &&
      !clientData['ga_client_id']
    )
      return

    postClientData(clientData, authenticity_token, dlToken)
  }, [authenticity_token, clientData, dlToken])

  useEffect(() => {
    if (clientData['trusted_form_token']) return
    // Select the node that will be observed for mutations
    const trustedFormObserverTarget = document.getElementById(
      'trustedFormFormElement',
    ) as HTMLInputElement
    if (!trustedFormObserverTarget) return

    const trustedFormTokenElement = document.getElementById(
      'xxTrustedFormToken_0',
    ) as HTMLInputElement
    if (trustedFormTokenElement && trustedFormTokenElement.value) {
      setClientData(c => {
        return {
          ...c,
          trusted_form_token: getElementValue('xxTrustedFormToken_0'),
          trusted_form_cert_url: getElementValue('xxTrustedFormCertUrl_0'),
          trusted_form_ping_url: getElementValue('xxTrustedFormPingUrl_0'),
        }
      })
      return
    }

    const trustedFormObserver = new MutationObserver(
      (_: MutationRecord[], observer: MutationObserver) => {
        setClientData(c => {
          return {
            ...c,
            trusted_form_token: getElementValue('xxTrustedFormToken_0'),
            trusted_form_cert_url: getElementValue('xxTrustedFormCertUrl_0'),
            trusted_form_ping_url: getElementValue('xxTrustedFormPingUrl_0'),
          }
        })
        observer.disconnect()
      },
    )

    // observer will only be triggered if the trusted form elements are not found
    trustedFormObserver.observe(trustedFormObserverTarget, { childList: true })

    return () => trustedFormObserver.disconnect()
  }, [clientData])

  useEffect(() => {
    if (clientData['universal_lead_id']) return
    // init observer to track leadId token(Jornaya)
    const leadIdObserverTarget = document.getElementById(LEAD_ID_ELEMENT_ID) as HTMLInputElement
    if (!leadIdObserverTarget) return

    if (leadIdObserverTarget && leadIdObserverTarget.value) {
      setClientData(c => {
        return {
          ...c,
          universal_lead_id: getElementValue(LEAD_ID_ELEMENT_ID),
        }
      })
    }

    const leadIdObserver = new MutationObserver(
      (_: MutationRecord[], observer: MutationObserver) => {
        setClientData(c => {
          return {
            ...c,
            universal_lead_id: getElementValue(LEAD_ID_ELEMENT_ID),
          }
        })
        observer.disconnect()
      },
    )

    leadIdObserver.observe(leadIdObserverTarget, { attributes: true })

    return () => leadIdObserver.disconnect()
  }, [clientData])

  useEffect(() => {
    if (disclosureBannerDisplayed) {
      setClientData(c => {
        return {
          ...c,
          disclosure_banner_display_time: disclosureBannerDisplayed,
        }
      })
    }
  }, [disclosureBannerDisplayed])

  return null
}
