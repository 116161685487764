import { getGlobalObject } from './getGlobalObject'

interface WindowWithDatadog {
  // We cannot type this property because this will require us to import datadog-rum package which
  // we don't want to bundle
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  DD_RUM: any
}

export function logError(error: Error | string, scope?: string | unknown) {
  let err = error
  if (typeof error === 'string') {
    err = new Error(error)
  }
  makeOrScheduleCall('addError', err, { scope })
}

export function logAction(action: string, extra?: string | Record<string, unknown>) {
  makeOrScheduleCall('addAction', action, extra)
}

export function setGlobalContextProperty(key: string, extra?: unknown) {
  makeOrScheduleCall('setGlobalContextProperty', key, extra)
}

export function addFeatureFlagEvaluation(key: string, value: string) {
  makeOrScheduleCall('addFeatureFlagEvaluation', key, value)
}

function makeOrScheduleCall(method: string, ...args: unknown[]) {
  const RumGlobal = getDDRUM()
  if (RumGlobal) {
    if (RumGlobal[method]) {
      RumGlobal[method](...args)
    } else {
      // DD_RUM is not loaded yet - schedule call
      RumGlobal.onReady(() => getDDRUM()[method](...args))
    }
  }
}

export function getDDRUM() {
  return getGlobalObject<WindowWithDatadog>().DD_RUM
}
