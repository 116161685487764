import { LazyExoticComponent, VoidFunctionComponent } from 'react'

import { SimpleFormComponentConfig } from 'types/simple-form-components/input-components'

import { HeroHeading, LearnMore } from './api'
import { NextButtonOptions } from './simple-form-components/button-components'

// ===============================================
// Generics
// -----------------------------------------------

export interface PlateConfig<N extends PlateComponentName = PlateComponentName> {
  name: string
  url: string

  component: N
  component_options?: PlateComponents[N]['options']

  /**
   * @example 'lead_data'
   * @example 'results'
   */
  funnel_stage: string

  /**
   * @example 'life'
   * @example 'medicare'
   */
  line_of_business: string

  /**
   * @example 'agent'
   * @example 'digital'
   * @example 'guide_rep'
   */
  sales_path: string

  [key: string]: unknown
}

/**
 * A form field `name` attribute, e.g. `<input name="foo" />`
 */
export type Field = string

export type FieldData = Record<Field, string | number>

export interface ServiceData {
  gon_data?: object
  tests?: Record<string, string>

  [name: string]: string | number | boolean | object | undefined | null
}

export type PlateComponent<P = BasePlateProps> = VoidFunctionComponent<P>
export type LazyPlateComponent<P = BasePlateProps> = LazyExoticComponent<PlateComponent<P>>

export type PlateComponentName = keyof PlateComponents

export interface PlateComponents {
  Buttons: ButtonsProps
  ImageButtons: ImageButtonsProps
  Results: ResultsProps
  Redirect: BasePlateProps
  SimpleForm: SimpleFormProps
  Loader: LoaderProps
  CallInfo: CallInfoProps
}

// ===============================================
// Plate Props
// -----------------------------------------------

interface BaseOptions {
  question?: QuestionConfig
  learn_more?: LearnMore
  hero_heading?: HeroHeading
  hero_footer?: HeroHeading
  replace_history?: boolean
  available_products_disclosure?: string
  next_button?: NextButtonOptions
  skip_button?: SkipButtonConfig

  [field: string]: unknown
}

interface FieldOptions extends BaseOptions {
  field: Field
}

export interface BasePlateProps {
  options?: BaseOptions
  field_data?: FieldData
  service_data?: ServiceData
}

export interface CreditPullProps extends BasePlateProps {
  field_data: {
    fname?: string
  }

  // TODO: Make the API return this within component_options
  service_data: {
    credit_pull: {
      balance?: number
      credit_rating?: 'excellent' | 'good' | 'average' | 'fair' | 'poor'
      credit_score?: number
      lender?: string
    }
  }
}

interface ButtonsOptions extends FieldOptions {
  question: QuestionConfig
  buttons: ButtonConfig[]
  /**
   * Define how buttons should be displayed:
   * - `column` (default) - buttons are displayed vertically
   * - `row` - buttons are displayed horizontally
   */
  layout?: 'row' | 'column'
}

export interface ButtonsProps extends BasePlateProps {
  options: ButtonsOptions
}

interface ImageButtonsOptions extends FieldOptions {
  question: QuestionConfig
  buttons: ImageButtonConfig[]
}

export interface ImageButtonsProps extends BasePlateProps {
  options: ImageButtonsOptions
}

interface SimpleFormOptions extends BaseOptions {
  question: QuestionConfig
  components: SimpleFormComponentConfig[]
  next_button?: NextButtonOptions
}

export interface SimpleFormProps extends BasePlateProps {
  options: SimpleFormOptions
  field_data: FieldData
}

export interface Metadata {
  lead_id: number
  direct_lead_id: number
  lead_type: string
  service: string
  environment: string
}

export interface RecommendedAndOther {
  other: Offering[] | undefined
  recommended: Offering[] | undefined
  recommendationHeading: string
  metadata: Metadata
}

interface ResultsOptions extends BaseOptions {
  offers: RecommendedAndOther
  trustpilot_profile: TrustpilotProfile
  adjust_quotes_fields: AdjustQuotesFieldsProps
}

export interface ResultsProps extends BasePlateProps {
  options: ResultsOptions
  field_data: FieldData
}

export interface CallInfoProps extends BasePlateProps {
  options: CallInfoPlateOptions
  field_data: FieldData
}

export enum CallUsNowPosition {
  Top = 'top',
  Bottom = 'bottom',
  None = 'none',
}
export interface CallInfoPlateBody {
  heading?: string
  text: string
  phone?: string
  phone_formatted?: string
  call_hours?: Array<string>
  tty?: number
  call_us_now_position?: CallUsNowPosition
  call_us_now_heading?: string
  call_us_now_sub_heading?: string
}
interface CallInfoPlateOptions extends BaseOptions {
  plate_body: CallInfoPlateBody
}

export interface CallInfoMetadata {
  direct_lead_id?: number | null
  lead_id?: number | null
}

interface ZestimateOptions extends BaseOptions {
  hidden_inputs: {
    zestimate_property_value: number
    [field: string]: number
  }
  next_button?: {
    options: {
      text: string
    }
  }
  title?: string
}

export interface ZestimateProps extends BasePlateProps {
  options: ZestimateOptions

  field_data: {
    address: string
  }
}

// ===============================================
// Plate Sub-Types
// -----------------------------------------------

export interface ButtonConfig {
  value: string | number
  text: string

  // TODO: Consider adding other types for other LOIs
  // SEE: https://github.com/assuranceiq/react-plates/pull/170#discussion_r978515310
  type?: 'button_aiq_link'
}

export interface SkipButtonConfig {
  field?: string
  value: string
  text: string
}

export interface BaseImageButtonConfig {
  value: string
  text: string | string[]
}

export interface ImageButtonConfig extends BaseImageButtonConfig {
  img_path: string
}

// TODO: Add support for HTML in text strings :(
export type QuestionConfig =
  | string
  | {
      text?: string
      super_text?: string
      sub_text?: string
    }

export interface TrustpilotProfile {
  stars: number
  reviews_count: number
}

export interface AdjustQuotesFieldsProps {
  coverage: string
  term: number
}

export enum OfferingType {
  AD = 'click_listing',
  QUOTE = 'quote',
}

export interface OfferingData {
  carrier?: string
  type_group?: string
  product_arn?: string
  coverage_amount?: number
  term_length?: number
  id?: number
  sold_by?: string
}

export interface Logo {
  src: string
  title: string
}

export interface Offering {
  product_type: OfferingType
  product_data?: OfferingData
  title: string
  generatedId?: string
  logo_url: Logo
  coverage?: {
    min: number
    max: number
  }
  term?: string
  term_length?: number
  estimated_monthly_cost: number
  click_url: string
  vendor_name?: string
  vendor_id?: number
  cpc?: number
  company_name?: string
  product_id?: number
  ad_id?: number
  quote_id?: number
  carrier?: string
  product_name?: string
  impressions?: {
    impr_html?: string
  }
  type_group?: string
  term_range?: string
}

export interface AdditionalOfferingFields {
  adsText: string
  termValue: string | null
  coverageValue: string | null
  clickUrl?: string
  variableProduct: boolean
}

export interface ExtendedOffering extends Offering, AdditionalOfferingFields {
  estimation: string
}

export interface Step {
  link?: string
  title: string
}

export interface Steps {
  steps: Step[]
}

export interface QuotesAndAdsProps {
  userName: string
  recommended: ExtendedOffering[]
  other: ExtendedOffering[]
  setSelectedOffer: (offer: ExtendedOffering) => void
  adjustQuotesFields: AdjustQuotesFieldsProps
  quotesHeading: string
  metadata: Metadata
  showAllOffers: boolean
  setShowAllOffers: (status: boolean) => void
}

export interface ApplyOfferProps {
  offer: ExtendedOffering
  onBack: () => void
  metadata: Metadata
}

export interface ViewOfferProps {
  recommended: ExtendedOffering[]
  other: ExtendedOffering[]
  setSelectedOffer: (offer: ExtendedOffering) => void
  trustPilotProfile: TrustpilotProfile
  userName: string
  adjustQuotesFields: AdjustQuotesFieldsProps
  quotesHeading: string
  metadata: Metadata
  showAllOffers: boolean
  setShowAllOffers: (status: boolean) => void
}

export interface LoaderProps extends BasePlateProps {
  field_data?: FieldData
}
