import { CSSProperties } from 'react'

import { useLoad3rdPartyScript } from 'hooks/useLoad3rdPartyScript'
import { useStoreState } from 'lib/store'

const TRUSTED_SITE_SCRIPT_ID = 'trusted-site-script'
const TRUSTED_SITE_SCRIPT_BASE = '//cdn.ywxi.net/js/1.js'
const WIDGET_WIDTH = 116
const WIDGET_HEIGHT = 48

/** @see https://www.trustedsite.com/login for more widget settings */
export enum StyleType {
  Colored = 202,
  Grey = 303,
}

// cached styles that are applied to the div when the widget is loaded on the development env
const CACHED_STYLES: Record<StyleType, CSSProperties> = {
  [StyleType.Grey]: {
    width: 100,
    height: 41,
    display: 'inline-block',
    backgroundImage:
      'url("https://cdn.ywxi.net/meter/assurance.com/303.svg?ts=1707407314447&color=7a848b")',
    backgroundSize: 'contain',
    backgroundPosition: 'center top',
    backgroundRepeat: 'no-repeat',
    cursor: 'pointer',
  },
  [StyleType.Colored]: {
    width: 116,
    height: 48,
    display: 'inline-block',
    backgroundImage:
      'url("https://cdn.ywxi.net/meter/staging.assurance.com/202.svg?ts=1677516554399&l=en-GB")',
    backgroundSize: 'contain',
    backgroundPosition: 'center top',
    backgroundRepeat: 'no-repeat',
    cursor: 'pointer',
  },
}

export interface TrustedSiteProps {
  type?: StyleType
  width?: number
  height?: number
}

export const TrustedSite = ({
  type = StyleType.Colored,
  width = WIDGET_WIDTH,
  height = WIDGET_HEIGHT,
  ...props
}: TrustedSiteProps) => {
  const direct_lead_id = useStoreState(state => state.current.context_data?.direct_lead_id)
  const style = process.env.NODE_ENV === 'development' ? CACHED_STYLES[type] : {}

  useLoad3rdPartyScript({
    id: TRUSTED_SITE_SCRIPT_ID,
    src: TRUSTED_SITE_SCRIPT_BASE,
    defer: true,
    deps: [direct_lead_id],
  })

  return (
    <div
      className='trustedsite-trustmark'
      data-type={type}
      data-width={width}
      data-height={height}
      style={style}
      {...props}
    />
  )
}
