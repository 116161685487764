import { useStoreState } from 'lib/store'

import styles from './GPCBanner.module.scss'

export const GPCBanner = () => {
  const show_gtm = useStoreState(state => state.current.context_data?.show_gtm)

  if (show_gtm) {
    // Do not show if no GPC signal
    return <></>
  }

  return (
    <div
      className={styles.banner}
      role='dialog'
      aria-labelledby='GPCBannerTitle'
      aria-describedby='GPCBannerDesc'
      id='GPCBannerTitle'
    >
      <div id='GPCBannerDesc' className={styles.desc}>
        Banner informs that browser is emitting Global Privacy signal
      </div>
      <span className={styles.indicator}></span>
      <span>Global Privacy Signal Detected</span>
    </div>
  )
}
