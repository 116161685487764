import { useFormContext } from 'react-hook-form'

import { useFieldRules } from 'hooks/useFieldRules'

import { useController } from '../FormProvider'
import { InputProps } from '../Input'

export const HiddenInput = ({
  field: name,
  rules: defaultRules,
  validateField,
  value,
}: InputProps) => {
  const { control } = useFormContext()
  const rules = useFieldRules({ defaultRules, validateField, field: name })

  useController({
    name,
    control,
    defaultValue: value ?? '', // Can not be undefined
    rules,
  })

  return null
}
