import Box, { BoxProps } from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { QuestionConfig } from 'types/plate'

import styles from './Question.module.scss'

export interface QuestionProps extends BoxProps {
  question: QuestionConfig
}

export const Question = ({ question: stringOrObject, ...boxProps }: QuestionProps) => {
  const question: QuestionConfig =
    typeof stringOrObject === 'string' ? { text: stringOrObject } : stringOrObject

  return (
    <Box mb={4} {...boxProps}>
      {question?.super_text && (
        <Typography
          variant='subtitle1'
          component='h3'
          gutterBottom
          // FIXME: investigate how to exclude dangerouslySetInnerHTML or sanitize the value
          dangerouslySetInnerHTML={{ __html: question.super_text }}
        />
      )}
      {question.text && (
        <Typography
          className={styles.text}
          variant='h3'
          component='h2'
          gutterBottom
          // FIXME: investigate how to exclude dangerouslySetInnerHTML or sanitize the value
          dangerouslySetInnerHTML={{ __html: question.text }}
        />
      )}
      {question?.sub_text && (
        <Typography
          variant='subtitle1'
          component='h3'
          gutterBottom
          className={styles.subtext}
          // FIXME: investigate how to exclude dangerouslySetInnerHTML or sanitize the value
          dangerouslySetInnerHTML={{ __html: question.sub_text }}
        />
      )}
    </Box>
  )
}
