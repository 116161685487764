import { SanitizedHTML } from 'components/SanitizedHTML'
import { generateUUID } from 'hooks/useUUIDs'
import {
  SimpleFormComponentProps,
  TcpaConsentConfig,
} from 'types/simple-form-components/input-components'

import styles from './TcpaConsent.module.scss'

export interface TcpaConsentProps extends SimpleFormComponentProps<TcpaConsentConfig> {}

export const TcpaConsent = ({ value }: TcpaConsentProps) => {
  const id = 'leadid_tcpa_disclosure_' + generateUUID()

  if (!value) return null

  return (
    <div className={styles.root}>
      {/* Need to wrap TCPA to track it in jornaya */}
      <label htmlFor={id}>
        <input type='hidden' id={id} />
        <SanitizedHTML dirtyHtml={value} />
      </label>
    </div>
  )
}
