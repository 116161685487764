import { useEffect, useRef } from 'react'

import { useStoreState } from '../../lib/store'
import { logAction, setGlobalContextProperty } from '../../utils/datadog'

export const ActionTracker = () => {
  useVigourouslyLeaving()
  useDLTracker()
  useVisibilityTracker()

  return null
}

const DL_MAP_LS_KEY = 'dlMap'

const useDLTracker = () => {
  const currentDLId = useStoreState(state => state.current.context_data?.direct_lead_id)

  useEffect(() => {
    if (!currentDLId || !localStorage) {
      return
    }
    const dlIdStr = currentDLId.toString()

    const dlMap = localStorage.getItem(DL_MAP_LS_KEY)
    const dlMapObj: Record<string, string> = dlMap ? JSON.parse(dlMap) : {}

    if (dlMap && !dlMapObj[dlIdStr]) {
      // user has previously assigned direct_lead_ids - log to DD
      // clone object to not include current dlId
      setGlobalContextProperty('previous_dl_ids', JSON.stringify({ ...dlMapObj }))
    }

    if (!dlMapObj[dlIdStr]) {
      dlMapObj[dlIdStr] = new Date().toISOString()
    }
    localStorage.setItem(DL_MAP_LS_KEY, JSON.stringify(dlMapObj))
  }, [currentDLId])
}

const VIGOROUS_BACK_BUTTON_INTERVAL = 6 * 1000
const VIGOROUS_BACK_BUTTON_THRESHOLD = 3

const useVigourouslyLeaving = () => {
  const backButtonCount = useRef<number>(0)
  const backButtonLeavingDetected = useRef<boolean>(false)

  useEffect(() => {
    const onPopState = () => {
      backButtonCount.current++
      setTimeout(() => {
        if (backButtonCount?.current) {
          backButtonCount.current--
        }
      }, VIGOROUS_BACK_BUTTON_INTERVAL)

      if (
        !backButtonLeavingDetected.current &&
        backButtonCount.current >= VIGOROUS_BACK_BUTTON_THRESHOLD
      ) {
        // Do not log more than once
        backButtonLeavingDetected.current = true
        logAction('back_button_leaving')
      }
    }
    window.addEventListener('popstate', onPopState)

    return () => window.removeEventListener('popstate', onPopState)
  }, [])
}

const useVisibilityTracker = () => {
  useEffect(() => {
    // Set initial value
    setGlobalContextProperty('tab_visibility_state', document.visibilityState)

    const onVisibilityChange = () => {
      const { visibilityState } = document

      setGlobalContextProperty('tab_visibility_state', visibilityState)
      logAction('tab_visibility_change', { tabVisibilityState: visibilityState })
    }

    document.addEventListener('visibilitychange', onVisibilityChange)
    return () => document.removeEventListener('visibilitychange', onVisibilityChange)
  }, [])
}
