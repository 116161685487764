import { FormEvent, lazy, useState } from 'react'

import Button, { ButtonProps } from '@mui/material/Button'

import clsx from 'clsx'

import { ButtonWithArrow } from 'components/ButtonWithArrow'
import { Progress } from 'components/Progress/Progress'
import { useStoreActions } from 'lib/store'
import {
  ButtonComponentProps,
  NextButtonConfig,
} from 'types/simple-form-components/button-components'

import { NextButtonWithTcpaDisclosure } from './NextButtonWithTcpaDisclosure'

import styles from './NextButton.module.scss'

const BUTTON_COMPONENTS = {
  NextButtonWithConsent: lazy(() => import('./NextButtonWithConsent')),
  NextButtonWithTcpaDisclosure: NextButtonWithTcpaDisclosure,
  NextButtonWithModal: lazy(() => import('./NextButtonWithModal')),
  'SimpleFormPlate::NextButtonWithSmsVerification': lazy(
    () => import('./NextButtonWithSmsVerification'),
  ),
}

export const NextButton = ({
  buttonProps: { className, ...otherButtonProps } = {},
  children = 'Continue',
  isPending = false,
  singleButton = false,
  nextButtonConfig,
  ...otherProps
}: ButtonComponentProps<NextButtonConfig>) => {
  const submit = useStoreActions(actions => actions.plate.submit)

  const onSubmitSkipButton = (ev: FormEvent<EventTarget>) => {
    ev.preventDefault()
    const field = nextButtonConfig?.options?.skip_button?.field ?? ''
    const value = nextButtonConfig?.options?.skip_button?.value ?? ''
    const isBadPayload = !field || !value

    if (isPlateBusy || isBadPayload) return

    setIsSkipButtonPending(true)
    submit({ [field]: value })
  }

  const [isSkipButtonPending, setIsSkipButtonPending] = useState<boolean>(false)
  const isPlateBusy = isPending || isSkipButtonPending

  const muiButtonProps: ButtonProps = {
    'aria-label': isPending ? 'Loading…' : undefined,
    'aria-disabled': isPlateBusy,
    className: clsx(styles.root, className, singleButton ? styles.singleContinueButton : ''),
    fullWidth: true,
    size: 'large',
    type: 'submit',
    variant: 'contained',
    ...otherButtonProps,
  }

  const muiSkipButtonProps: ButtonProps = {
    'aria-label': isSkipButtonPending ? 'Skipping…' : 'Skip this page',
    'aria-disabled': isPlateBusy,
    className: clsx(styles.root, styles.skipButton),
    size: 'small',
    type: 'submit',
    variant: 'text',
    onClick: event => onSubmitSkipButton(event),
    ...otherButtonProps,
  }

  const shouldRenderSkipButton = !!nextButtonConfig?.options?.skip_button

  const skipButtonText = nextButtonConfig?.options?.skip_button?.text ?? 'Skip'
  const nextButtonText = nextButtonConfig?.options?.text ?? children

  if (!nextButtonConfig || !nextButtonConfig.component) {
    return (
      <>
        <Button {...muiButtonProps}>
          {isPending && !isSkipButtonPending ? <Progress /> : nextButtonText}
        </Button>
        {shouldRenderSkipButton && (
          <Button {...muiSkipButtonProps}>
            {isSkipButtonPending ? <Progress /> : <ButtonWithArrow name={skipButtonText} />}
          </Button>
        )}
      </>
    )
  }

  const CustomNextButton = BUTTON_COMPONENTS[nextButtonConfig.component]

  return (
    <>
      <CustomNextButton
        buttonProps={muiButtonProps}
        isPending={isPlateBusy}
        options={nextButtonConfig.options}
        {...otherProps}
      >
        {isPending ? <Progress /> : nextButtonText}
      </CustomNextButton>
      {shouldRenderSkipButton && (
        <Button {...muiSkipButtonProps}>
          {isSkipButtonPending ? <Progress /> : skipButtonText}
        </Button>
      )}
    </>
  )
}
