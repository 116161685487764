import { useEffect, useState } from 'react'

// Time to wait before loading 3rd party resources
// Delaying them gives app a chance to initialize and run
export const DELAY_3RD_PARTY_SCRIPTS = 2000

interface useLoad3rdPartyScriptProps {
  /** Should be unique across DOM to prevent re-instantiation of the script */
  id: string
  src: string
  loadIf?: () => boolean

  /** @default true */
  async?: boolean

  /** @default false */
  defer?: boolean

  delay?: number
  deps?: Array<string | number | undefined>
}

export const useLoad3rdPartyScript = ({
  id,
  src,
  loadIf,
  async = true,
  defer = false,
  delay = DELAY_3RD_PARTY_SCRIPTS,
  deps = [],
}: useLoad3rdPartyScriptProps) => {
  const [appended, setAppended] = useState(false)

  useEffect(() => {
    if (loadIf && !loadIf()) return
    if (document.getElementById(id)) return // Prevent re-instantiation

    const timeout = setTimeout(() => {
      const script = document.createElement('script')

      script.id = id
      script.src = src

      if (async) script.async = true
      if (defer) script.defer = true

      document.head.appendChild(script)

      setAppended(true)
    }, delay)

    return () => clearTimeout(timeout)
    // eslint can't track dependencies with `...deps` in place
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps, id, src, loadIf, async, defer, delay])

  return { appended }
}
