import { useEffect, useState } from 'react'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'

import clsx from 'clsx'

import { Question } from 'components/Question'
import { NextButton } from 'components/SimpleForm/NextButton'
import { SkipButton } from 'components/SkipButton/SkipButton'
import { useUUID } from 'hooks/useUUID'
import { useStoreActions, useStoreState } from 'lib/store'
import { ButtonsProps, PlateComponent } from 'types/plate'

import styles from './Buttons.module.scss'

export const Buttons: PlateComponent<ButtonsProps> = ({
  options: { field, question, buttons, layout = 'column', skip_button },
  field_data: { [field]: fieldDataValue } = {},
}) => {
  const isPending = useStoreState(state => state.plate.isPending)
  const submit = useStoreActions(actions => actions.plate.submit)

  const [pendingValue, setPendingValue] = useState<string | number>()
  const questionID = useUUID()

  const onSubmit = (value: string | number) => {
    if (isPending) return

    setPendingValue(value)
    submit({ [field]: value })
  }

  useEffect(() => {
    if (isPending) return

    setPendingValue(undefined)
  }, [isPending])

  return (
    <Box component='fieldset' className={styles.root}>
      <Question component='legend' id={questionID} question={question} />
      <Grid container justifyContent='center' gap={3} role='group' aria-describedby={questionID}>
        <Grid container item justifyContent='center' xs={12} sm={6} md={6} spacing={1.5}>
          {buttons.map(({ value, text, type }) => (
            <Grid key={value} item xs={layout === 'row' ? 6 : 12}>
              <NextButton
                buttonProps={{
                  'aria-describedby': questionID,
                  'aria-disabled': isPending,
                  className: clsx({
                    [styles.highlighted]: fieldDataValue === value,
                    [styles.outlinedButton]: type === 'button_aiq_link',
                  }),
                  endIcon: null,
                  onClick: () => onSubmit(value),
                  ...(type === 'button_aiq_link' && { variant: 'outlined' }),
                }}
                isPending={pendingValue === value}
              >
                {text}
              </NextButton>
            </Grid>
          ))}
        </Grid>

        {skip_button && (
          <Grid
            container
            justifyContent='center'
            gap={3}
            role='group'
            aria-describedby={questionID}
          >
            <Grid container item justifyContent='center' xs={1} sm={1} md={1}>
              <SkipButton
                questionID={questionID}
                field={field}
                config={skip_button}
                tabIndex={buttons.length + 1}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Box>
  )
}
