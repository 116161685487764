import { useEffect } from 'react'

import { useFormContext } from 'react-hook-form'

import { useFeatureFlag } from 'hooks/useFeatureFlag'
import {
  InputMaskOptions,
  PhoneConfig,
  SimpleFormComponentProps,
} from 'types/simple-form-components/input-components'

import { Input } from '../Input'

export type PhoneProps = SimpleFormComponentProps<PhoneConfig>

const PHONE_REGEX = '^\\d{10}$'
/**
 * Regexp to allow manually entered phone number with separators and optional whitespaces in the beginning and the end
 * 123-456-7890
 * (123) 456-7890
 * 123 456 7890
 * 123.456.7890
 */
const PHONE_REGEX_WITH_SEPARATORS =
  '^\\s*(\\+\\d{1,2}\\s?)?\\(?\\d{3}\\)?[\\s.-]?\\d{3}[\\s.-]?\\d{4}\\s*$'

const PHONE_MASK = '(000) 000-0000'

export const Phone = ({
  attrs,
  field,
  placeholder = 'Enter Phone',
  mask_options,
  rules,
  value: propValue,
  validation,
  ...inputProps
}: PhoneProps) => {
  const { getValues, setValue } = useFormContext()
  const tcpaPhoneFeatureFlag = useFeatureFlag('life_test_phone_tcpa_spo1809')

  useEffect(() => {
    if (!process.env.REACT_APP_COUNTRY_CODE) throw new Error('No REACT_APP_COUNTRY_CODE defined')

    const value = propValue ?? getValues(field)

    // Reproduced behaviour from Rails
    // SEE: https://github.com/assuranceiq/insurance/blob/091ee6e885621abc20f4c0f170e62e21252db76d/app/lib/parser/phone.rb#L8
    const localNumber = value?.replace(process.env.REACT_APP_COUNTRY_CODE, '')

    setValue(field, localNumber)
  }, [field, getValues, propValue, setValue])

  let maskOptions: InputMaskOptions = {
    mask: PHONE_MASK,
    lazy: false,
    overwrite: 'shift',
    ...mask_options,
  }
  let validationPattern = { value: PHONE_REGEX, message: 'Phone number must be 10 digits' }

  if (tcpaPhoneFeatureFlag === 'show') {
    maskOptions = { ...mask_options }
    validationPattern = {
      value: PHONE_REGEX_WITH_SEPARATORS,
      message: 'Phone number must be in format (555) 123-4567',
    }
  }

  return (
    <Input
      {...inputProps}
      attrs={{
        inputMode: 'numeric',
        autoComplete: 'tel-national',
        ...attrs,
      }}
      field={field}
      mask_options={maskOptions}
      placeholder={placeholder}
      validation={{
        required: 'Please provide your phone number',
        pattern: validationPattern,
        ...validation,
      }}
    />
  )
}
