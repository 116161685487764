import { useEffect, useMemo } from 'react'

import MuiCheckbox from '@mui/material/Checkbox'
import FormControl from '@mui/material/FormControl'
import FormGroup from '@mui/material/FormGroup'
import FormHelperText from '@mui/material/FormHelperText'
import FormLabel from '@mui/material/FormLabel'

import clsx from 'clsx'
import { UseControllerProps, useFormContext } from 'react-hook-form'

import { useFieldRules } from 'hooks/useFieldRules'
import { useUUID } from 'hooks/useUUID'
import {
  CheckboxesConfig,
  SimpleFormComponentProps,
} from 'types/simple-form-components/input-components'

import { FormControlLabel } from '../FormControlLabel'
import { useController } from '../FormProvider'

import styles from './Checkboxes.module.scss'

export interface CheckboxesProps extends SimpleFormComponentProps<CheckboxesConfig> {
  field: string
}

export type CheckboxesFieldValues = Record<string, Array<string>>

export const Checkboxes = ({
  attrs: { 'aria-labelledby': ariaLabelledBy, className, ...attrs } = {},
  field: groupField,
  label,
  options = [],
  rules: defaultRules,
  validateField,
  validation,
}: CheckboxesProps) => {
  const { control, getValues, setValue } = useFormContext()

  const defaultValue: Array<string> = useMemo(() => {
    const hookFormValues = getValues()
    if (hookFormValues[groupField] && Array.isArray(hookFormValues[groupField])) {
      return hookFormValues[groupField]
    }

    return options.reduce((acc, { field, text, value }) => {
      let optionDefaultValue: boolean | string | undefined = hookFormValues[field] ?? value

      if (typeof optionDefaultValue === 'string') {
        console.error(
          `The value of the option "${text}" is a string type value. The value of the option will be set to false.`,
        )

        optionDefaultValue = false
      }

      if (optionDefaultValue) {
        acc.push(text)
      }

      return acc
    }, [] as Array<string>)
  }, [getValues, groupField, options])

  const rules = useFieldRules({ defaultRules, validateField, field: groupField, validation })

  const { field, fieldState } = useController({
    name: groupField,
    control,
    defaultValue,
    rules,
  } as UseControllerProps<CheckboxesFieldValues>)

  const labelId = useUUID()
  const helperTextID = useUUID()

  useEffect(() => {
    options.forEach(({ field: optionField, text }) => {
      const checked = defaultValue.includes(text)

      // need to set value for each option field to set an appropriate form state
      // when the form is submitted
      setValue(optionField, checked)
    })
  }, [defaultValue, options, setValue])

  const handleChange = (optionField: string, optionText: string) => {
    const groupFieldValue = (getValues(groupField) as Array<string>) || []
    const checked = !groupFieldValue.includes(optionText)

    if (checked) {
      field.onChange([...groupFieldValue, optionText])
    } else {
      field.onChange(groupFieldValue.filter(value => value !== optionText))
    }

    setValue(optionField, checked)
  }

  return (
    <FormControl className={styles.root} fullWidth>
      <div className={styles.subtextContainer}>Select all that apply</div>
      {label && (
        <FormLabel className={styles.label} id={labelId} error={!!fieldState.error}>
          {label}
        </FormLabel>
      )}
      <FormGroup
        aria-describedby={fieldState.error ? undefined : helperTextID}
        aria-errormessage={fieldState.error ? helperTextID : undefined}
        aria-invalid={!!fieldState.error}
        aria-labelledby={label ? labelId : ariaLabelledBy}
        className={clsx(styles.formGroup, className)}
        {...attrs}
      >
        {options.map(option => {
          const checked = field.value.includes(option.text)

          return (
            <FormControlLabel
              key={option.field}
              label={option.text}
              checked={checked}
              error={!!fieldState.error}
              control={
                <MuiCheckbox
                  onChange={() => handleChange(option.field, option.text)}
                  checked={checked}
                />
              }
            />
          )
        })}
      </FormGroup>
      {fieldState.error && (
        <FormHelperText className={styles.error} id={helperTextID}>
          {fieldState.error?.message}
        </FormHelperText>
      )}
    </FormControl>
  )
}
