import {
  EmbedElementConfig,
  SimpleFormComponentProps,
} from 'types/simple-form-components/input-components'

export type EmbedElementProps = SimpleFormComponentProps<EmbedElementConfig>

export const EmbedElement = ({ element }: EmbedElementProps) => {
  return element || null
}
