import React from 'react'

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

import styles from './ButtonWithArrow.module.scss'

export interface ButtonWithArrowProps {
  name: string | string[]
}

export const ButtonWithArrow: React.FC<ButtonWithArrowProps> = ({ name }) => {
  return (
    <>
      {name} <ArrowForwardIosIcon className={styles.buttonIcon} />
    </>
  )
}
