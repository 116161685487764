import MuiFormControlLabel from '@mui/material/FormControlLabel'
import Typography from '@mui/material/Typography'

import clsx from 'clsx'

import { ReactComponent as SignWarning } from 'images/icons/sign-warning.svg'

import styles from './FormControlLabel.module.scss'

interface LabelWithSignProps {
  label: React.ReactNode
  error: boolean
}

export interface FormControlLabelProps extends React.ComponentProps<typeof MuiFormControlLabel> {
  error?: boolean
}

const LabelWithSign = ({ label, error }: LabelWithSignProps) => {
  return (
    <div className={styles.label}>
      <Typography component='span' className={styles.labelText}>
        {label}
      </Typography>
      {error && <SignWarning className={styles.errorIcon} />}
    </div>
  )
}

/**
 * Styled MUI’s FormControlLabel component with custom label and error icon
 */
export const FormControlLabel = ({
  checked,
  className,
  label,
  error = false,
  ...otherProps
}: FormControlLabelProps) => {
  return (
    <MuiFormControlLabel
      className={clsx(
        styles.root,
        {
          [styles.checked]: checked,
          [styles.error]: error,
        },
        className,
      )}
      label={<LabelWithSign label={label} error={error} />}
      {...otherProps}
    />
  )
}
