import { useStoreState } from 'lib/store'

export function useFeatureFlag(flagName: string): string | undefined {
  if (process.env.NODE_ENV === 'test') {
    return
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useStoreState(state => {
    const tests = state.plate.data?.context?.service_data?.tests
    if (tests) {
      return tests[flagName]
    }
  })
}
