import { memo, useEffect, useMemo } from 'react'

import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'

import { Loading } from '@assuranceiq/react-components/components/Loading'
import { useSnackbar } from '@assuranceiq/react-components/hooks/useSnackbar'

import { LayoutLoader } from 'components/LayoutLoader'
import { PlateLoader } from 'components/PlateLoader'
import { useStoreActions, useStoreState } from 'lib/store'
import { PromiseStatus } from 'types/promise'
import { STARTING_PLATE } from 'utils/constants'
import { logAction, logError } from 'utils/datadog'
import { urlToAPIFormat } from 'utils/url-formatting'

import styles from './PlatePresenter.module.scss'

interface ConditionalLayoutProps {
  children: React.ReactNode
  show: boolean
}

interface PlatesPresenterProps {
  modalStartingPlate?: string
}

const ConditionalLayout = memo(function ConditionalLayout({
  children,
  show,
}: ConditionalLayoutProps) {
  return show ? <LayoutLoader>{children}</LayoutLoader> : <>{children}</>
})

export function PlatesPresenter({ modalStartingPlate }: PlatesPresenterProps) {
  const status = useStoreState(state => state.plate.status)
  const get = useStoreActions(actions => actions.plate.get)
  const inModal = !!modalStartingPlate

  const getPlate = useMemo(() => {
    return () => {
      const searchParams = new URLSearchParams(window.location.search)
      searchParams.delete('format')
      const queryParams = searchParams.toString().length === 0 ? '' : `?${searchParams.toString()}`

      if (modalStartingPlate) {
        return get(urlToAPIFormat(modalStartingPlate))
      }

      if (window.location.pathname === '/') {
        get(urlToAPIFormat(STARTING_PLATE + queryParams))
      } else {
        get(urlToAPIFormat(window.location.pathname + queryParams))
      }
    }
  }, [get, modalStartingPlate])

  useEffect(() => {
    getPlate()
  }, [getPlate])

  switch (status) {
    case PromiseStatus.IDLE: {
      return <Loading fullscreen />
    }
    case PromiseStatus.REJECTED:
      return (
        <ConditionalLayout show={!inModal}>
          <AlertMessage tryAgain={getPlate} />
        </ConditionalLayout>
      )
    case PromiseStatus.PENDING:
    case PromiseStatus.RESOLVED: {
      return (
        <ConditionalLayout show={!inModal}>
          <PlateLoader inModal={inModal} />
          <AlertMessage tryAgain={getPlate} />
        </ConditionalLayout>
      )
    }
  }
}

interface AlertMessageProps {
  tryAgain: () => void
}

const AlertMessage = ({ tryAgain }: AlertMessageProps) => {
  const { error, errorData, isResolved, isRejected, status } = useStoreState(state => state.plate)
  const { showSnackbar } = useSnackbar()

  useEffect(() => {
    if (errorData?.session?.session_expired) {
      showSnackbar('Your session has expired', 'info')
      logAction('session_expired')
    }
  }, [errorData, showSnackbar])

  if (!isRejected || (isResolved && !errorData?.context)) return null

  // Application is in error state.
  logError('plate_error', {
    errorData,
    error,
    status,
  })

  return (
    <Grid container flexDirection='column' justifyContent='center' alignItems='center' my={4}>
      <Grid item xs={12} sm={8} lg={6}>
        <Alert severity='error'>
          {errorData?.message || error?.message || 'Unable to load plate config'}
        </Alert>
      </Grid>
      <Grid item xs={12} sm={8} lg={6}>
        <Button className={styles.retryButton} variant='text' size='small' onClick={tryAgain}>
          Try again
        </Button>
      </Grid>
    </Grid>
  )
}
