import React, { useEffect, useRef, useState } from 'react'

import Button from '@mui/material/Button'
import MuiCheckbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'

import { useController, useForm, useFormContext } from 'react-hook-form'

import { SanitizedHTML } from 'components/SanitizedHTML'
import { generateUUID } from 'hooks/useUUIDs'
import { ArrowButtonRightIcon } from 'icons'
import {
  DisclosureConfig,
  SimpleFormComponentProps,
} from 'types/simple-form-components/input-components'
import { logAction } from 'utils/datadog'

import styles from './Disclosure.module.scss'

export interface DisclosureProps extends SimpleFormComponentProps<DisclosureConfig> {}

export const Disclosure = ({ value, input, onScrolledToEnd, onChecked }: DisclosureProps) => {
  const [leadIdValue] = React.useState<string>(generateUUID())
  const { control, setValue } = useFormContext()
  const { trigger } = useForm()
  const endOfDisclosureRef = useRef<HTMLDivElement>(null)
  const [isManuallyChecked, setIsManuallyChecked] = useState<boolean>(input?.type === 'hidden')
  const [hasScrolledToEnd, setHasScrolledToEnd] = useState<boolean>(false)
  const [scrollButtonPressed, setScrollButtonPressed] = useState(false)

  useEffect(() => {
    const currentEndOfDisclosureRef = endOfDisclosureRef.current

    if (typeof IntersectionObserver !== 'undefined' && currentEndOfDisclosureRef) {
      const observer = new IntersectionObserver(
        entries => {
          const [entry] = entries

          if (entry.isIntersecting) {
            setHasScrolledToEnd(true)
            logAction('scrolled')
            if (onScrolledToEnd) {
              onScrolledToEnd()
            }
          }
        },
        { threshold: 1 },
      )
      observer.observe(currentEndOfDisclosureRef)

      return () => {
        observer.unobserve(currentEndOfDisclosureRef)
      }
    }
  }, [onScrolledToEnd])

  // input field is always present
  const name = input?.field as string

  const { field } = useController({
    name,
    control,
    defaultValue: '',
    rules: {
      validate: () => {
        return isManuallyChecked ? hasScrolledToEnd : true
      },
    },
  })

  useEffect(() => {
    // Only run this if scroll button was not pressed
    if (!scrollButtonPressed) {
      setValue(name, isManuallyChecked.toString(), {
        shouldValidate: true,
        shouldTouch: true,
        shouldDirty: true,
      })
      trigger(name)
    } else {
      // Reset scrollButtonPressed to false for future interactions
      setScrollButtonPressed(false)
    }
  }, [isManuallyChecked, hasScrolledToEnd, name, setValue, trigger, scrollButtonPressed])

  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsManuallyChecked(event.target.checked)
    if (onChecked) {
      onChecked(event.target.checked)
    }
  }

  const id = 'leadid_tcpa_disclosure_' + leadIdValue

  const scrollToBottom = () => {
    setScrollButtonPressed(true) // Set to true when scroll button is pressed
    setHasScrolledToEnd(true)
    endOfDisclosureRef.current?.scrollIntoView({
      behavior: 'smooth',
    })

    if (onScrolledToEnd) {
      onScrolledToEnd()
    }
  }

  return (
    <div className={styles.disclosureContainer}>
      <div>
        {input?.type === 'checkbox' && (
          <FormControlLabel
            control={
              <MuiCheckbox
                color='primary'
                name={field.name}
                value={field.value}
                id={id}
                ref={field.ref}
                checked={isManuallyChecked}
                onChange={handleCheck}
              />
            }
            label={input.label}
            classes={{ label: styles.checkbox }}
          />
        )}
        <div className={styles.disclosure}>
          {value && (
            <div className={styles.tcpaConsent}>
              <label htmlFor={id}>
                {input?.type === 'hidden' && (
                  <input
                    type='hidden'
                    name={field.name}
                    value={field.value}
                    ref={field.ref}
                    id={id}
                  />
                )}
                <SanitizedHTML dirtyHtml={value} />
              </label>
              <div ref={endOfDisclosureRef}></div>
            </div>
          )}
        </div>
      </div>
      {!hasScrolledToEnd && (input?.type !== 'checkbox' || isManuallyChecked) && (
        <div className={styles.scrollButton}>
          <Button
            className={styles.scrollToEnd}
            endIcon={<ArrowButtonRightIcon className={styles.arrowDown} />}
            onClick={scrollToBottom}
            variant='contained'
            disabled={input?.type === 'checkbox' && !isManuallyChecked}
          >
            Scroll
          </Button>
        </div>
      )}
    </div>
  )
}
