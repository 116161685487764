import Typography from '@mui/material/Typography'

import {
  DentalPlansListConfig,
  SimpleFormComponentProps,
} from 'types/simple-form-components/input-components'

import styles from './DentalPlansList.module.scss'

export type DentalPlansListProps = SimpleFormComponentProps<DentalPlansListConfig>
export const DentalPlansList = ({ plans_list = [] }: DentalPlansListProps) => {
  return (
    <div className={styles.root}>
      {plans_list.map(plan => (
        <Typography className={styles.row} key={plan.quantity + plan.carrier_name}>{`${
          plan.quantity
        } ${plan.carrier_name} plan${plan.quantity > 1 ? 's' : ''}`}</Typography>
      ))}
    </div>
  )
}
