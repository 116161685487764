import { useMemo } from 'react'

interface DeviceType {
  isMobile: boolean
  isTablet: boolean
  isDesktop: boolean
}

export const useDeviceType = (): DeviceType => {
  return useMemo(() => {
    const ua = navigator.userAgent

    const isMobile = /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(ua)
    const isTablet = /iPad|Tablet|PlayBook|Nexus 7|Nexus 10|KFAPWI|KFTHWI|KFJWI/i.test(ua)

    return { isMobile, isTablet, isDesktop: !isMobile && !isTablet }
  }, [])
}
