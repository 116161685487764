// Inspired by https://github.com/DataDog/rum-react-integration-examples/tree/master/src/ErrorBoundary
import { Component, isValidElement, ReactNode } from 'react'

import { logError } from 'utils/datadog'

type FallbackRenderer = (error: Error) => React.ReactNode

interface ErrorBoundaryState {
  hasError: boolean
  error?: Error
  prevScope?: string
}

export interface ErrorBoundaryProps {
  fallback: ReactNode | FallbackRenderer
  scope?: string
}

/**
 * ErrorBoundary component sends enriched errors to RUM.
 */
export class DatadogErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  static defaultProps = {
    scope: 'error-boundary',
  }

  // @ts-ignore
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false, prevScope: props.scope }
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error }
  }

  static getDerivedStateFromProps(props: ErrorBoundaryProps, state: ErrorBoundaryState) {
    if (state.prevScope !== props.scope) {
      return {
        hasError: false,
        error: undefined,
        prevScope: props.scope,
      }
    }

    return state
  }

  componentDidCatch(error: Error) {
    logError(error, this.props.scope)
  }

  render() {
    const { hasError, error } = this.state
    const { fallback } = this.props

    if (!hasError || !error) {
      return this.props.children
    }

    if (isValidElement(fallback) || typeof fallback === 'string') {
      return fallback
    } else if (typeof fallback === 'function') {
      return fallback(error)
    }

    return null
  }
}
