import { HTMLProps, lazy, LazyExoticComponent, Suspense, useState } from 'react'

import Link from '@mui/material/Link'

import clsx from 'clsx'

import type { ModalDialogType } from 'components/ModalDialog'
import { SanitizedHTML } from 'components/SanitizedHTML'

import { ReactComponent as ExternalLinkIcon } from 'images/icons/external-link.svg'

import styles from './InfoPanel.module.scss'

export interface InfoPanelProps extends HTMLProps<HTMLParagraphElement> {
  main: string
  extra?: string
  href?: string
  isOpened?: boolean
}

export const InfoPanel = ({
  main,
  extra,
  href,
  className,
  isOpened: isOpenedInitially = false,
  ...props
}: InfoPanelProps) => {
  const [isOpened, setIsOpened] = useState<boolean>(isOpenedInitially)
  const LazyModalDialog = lazy(
    () => import('components/ModalDialog'),
  ) as LazyExoticComponent<ModalDialogType>

  function toggleModal() {
    setIsOpened(!isOpened)
  }

  return (
    <p className={clsx(styles.main, className)} {...props}>
      <SanitizedHTML dirtyHtml={main} />
      {extra && (
        <>
          {' '}
          <Link className={styles.link} component='button' onClick={toggleModal}>
            Learn more
          </Link>
          {isOpened && (
            <Suspense fallback={<></>}>
              <LazyModalDialog
                a11yTitle='Dialog that provides learn more information'
                isOpened={isOpened}
                setIsOpened={setIsOpened}
                body={
                  <>
                    <p>
                      <SanitizedHTML dirtyHtml={extra} />
                    </p>
                    {href && (
                      <Link href={href} target='_blank' rel='noopener'>
                        Learn more <ExternalLinkIcon />
                      </Link>
                    )}
                  </>
                }
              />
            </Suspense>
          )}
        </>
      )}
    </p>
  )
}
