// It is an enhanced version of FormControlLabel which will replace original component, when LIFE team migrates to this one
// TODO: https://assuranceiq.atlassian.net/browse/GR-122

import MuiFormControlLabel from '@mui/material/FormControlLabel'
import Typography from '@mui/material/Typography'

import clsx from 'clsx'

import { ReactComponent as SignWarning } from 'images/icons/sign-warning.svg'

import { useUUID } from '../../../hooks/useUUID'

import styles from './FormControlLabelWithSubtext.module.scss'

interface LabelWithSignProps {
  label: React.ReactNode
  subtext: React.ReactNode
  error: boolean
}

export interface FormControlLabelProps extends React.ComponentProps<typeof MuiFormControlLabel> {
  error?: boolean
  subtext?: string
}

const LabelWithSign = ({ label, subtext, error }: LabelWithSignProps) => {
  const labelSubtext = useUUID()

  return (
    <div className={clsx(styles.label, subtext && styles.withSubtext)}>
      <Typography
        component='span'
        aria-describedby={subtext ? labelSubtext : undefined}
        className={styles.labelText}
      >
        {label}
      </Typography>
      {subtext && (
        <Typography id={labelSubtext} className={styles.subtext}>
          {subtext}
        </Typography>
      )}
      {error && <SignWarning aria-hidden='true' className={styles.errorIcon} />}
    </div>
  )
}

/**
 * Styled MUI’s FormControlLabel component with custom label and error icon
 */
export const FormControlLabelWithSubtext = ({
  checked,
  className,
  label,
  subtext,
  error = false,
  ...otherProps
}: FormControlLabelProps) => {
  return (
    <MuiFormControlLabel
      className={clsx(
        styles.root,
        {
          [styles.checked]: checked,
          [styles.error]: error,
        },
        className,
      )}
      label={<LabelWithSign label={label} subtext={subtext} error={error} />}
      {...otherProps}
    />
  )
}
