import Colors from '@assuranceiq/react-components/styles/colors/_colors.scss'
import { createAssuranceTheme } from '@assuranceiq/react-components/styles/createAssuranceTheme'

import SliderConfig from 'components/SimpleForm/Slider/Slider.config.scss'

import ProjColors from './config/_proj-colors.scss'
import Spacing from './config/_spacing.scss'
import Typography from './config/_typography.scss'

const fontFamily = [
  '"Open Sans"',
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  'Roboto',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
].join(',')

// TODO: Migrate to ARC
export const theme = createAssuranceTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        root: { fontFamily },
        '[aria-disabled="true"]': {
          pointerEvents: 'none',
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          fontFamily: 'inherit',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          borderRadius: '200px',
          minHeight: '54px',
          paddingTop: '0',
          paddingBottom: '0',
          fontSize: Typography['font-size-base'],
          fontWeight: Typography['font-weight-bold'],
          textTransform: 'none',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: 'inherit',
          padding: Spacing['unit'],
          '& .MuiSvgIcon-root': {
            fontSize: Typography['font-size-xx-large'],
          },
          '&.Mui-checked': {
            color: 'inherit',
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: 'inherit',
          padding: '10px',
          '& .MuiSvgIcon-root': {
            fontSize: 20,
          },
          '&.Mui-checked': {
            color: 'inherit',
          },
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          height: Spacing['unit'],
        },
        mark: {
          display: 'none',
        },
        marked: {
          display: 'flex',
          justifyContent: 'space-between',
        },
        markLabel: {
          color: Colors['primary'],
          position: 'static',
          pointerEvents: 'none',
          marginTop: '16px',
          transform: 'none',
        },
        rail: {
          backgroundColor: SliderConfig['rail-bg-color'],
          border: `1px solid ${SliderConfig['rail-border-color']}`,
          opacity: 1,
        },
        thumb: {
          width: '16px',
          height: '16px',
          '&:before': {
            boxShadow: 'none',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          boxShadow: ProjColors['focused-input-box-shadow'],
          color: ProjColors['gray-70'],
          '&.MuiOutlinedInput-root': {
            borderRadius: '5px',
          },
          '&.Mui-error': {
            color: Colors['error'],
          },
          '&.Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
            borderWidth: '1px',
            borderColor: Colors['info-light'],
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: Colors['common-black'],
          '&.Mui-focused': {
            color: Colors['common-black'],
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        gutterBottom: {
          marginBottom: Spacing['unit'],
        },
        h1: {
          fontFamily: fontFamily,
        },
        h2: {
          fontFamily: fontFamily,
        },
        h3: {
          fontFamily: fontFamily,
          fontSize: Typography['font-size-xx-large'],
          fontWeight: Typography['font-weight-bold'],
          lineHeight: Typography['line-height-xx-large'],
        },
      },
    },
  },

  typography: { fontFamily },
})
