import { lazy } from 'react'

import { LayoutComponentName, LayoutComponentProps, LazyLayoutComponent } from 'types/layout'

type LazyLayoutComponentMap = {
  [N in LayoutComponentName]: LazyLayoutComponent<LayoutComponentProps<N>>
}

export const lazyLayouts: LazyLayoutComponentMap = {
  Medicare: lazy(() => import('./MedicareLayout')),
  Life: lazy(() => import('./LifeLayout')),
  Dental: lazy(() => import('./DentalLayout')),
}
