import {
  BaseSimpleFormComponentConfig,
  SimpleFormComponentProps,
} from 'types/simple-form-components/input-components'

export const onStorybookDocsPage = () =>
  process.env.STORYBOOK && window.top?.location.search.includes('path=/docs/')

export const convertToFormProps = <
  Config extends BaseSimpleFormComponentConfig = BaseSimpleFormComponentConfig,
>(
  configWithComponent: Config,
): SimpleFormComponentProps<Config> => {
  const { component, ...config } = configWithComponent
  return config
}
