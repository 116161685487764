import { useEffect, useMemo, useState } from 'react'

import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import FormLabel from '@mui/material/FormLabel'
import MUISlider from '@mui/material/Slider'

import clsx from 'clsx'
import { UseControllerProps, useFormContext } from 'react-hook-form'

import { useFieldRules } from 'hooks/useFieldRules'
import { useUUID } from 'hooks/useUUID'
import {
  SimpleFormComponentProps,
  SliderConfig,
} from 'types/simple-form-components/input-components'

import { useController } from '../FormProvider'

import styles from './Slider.module.scss'

// NOTE: we use null because `react-hook-form` doesn’t allow undefined as a defaultValue
const UNSET = null

export type SliderProps = SimpleFormComponentProps<SliderConfig>

export type SliderFieldValues = Record<string, number | typeof UNSET>

export const Slider = ({
  field: name,
  slider_data,
  validation,
  placeholder = 'Please select a value',
  min: hardcodeMin = 0,
  max: hardcodeMax = 100,
  min_label: configMinLabel,
  max_label: configMaxLabel,
  step = 1,
  steps: stepObject = {},
}: SliderProps) => {
  const { control, getValues, setValue } = useFormContext()
  const rules = useFieldRules({ defaultRules: { required: 'Please select a value' }, validation })

  const {
    field: {
      ref: hookFormRef,
      onBlur: hookFormOnBlur,
      onChange: hookFormOnChange,
      value: hookFormValue,
    },
    fieldState,
  } = useController({
    name,
    control,
    defaultValue: slider_data?.default_value || UNSET,
    rules,
  } as UseControllerProps<SliderFieldValues>)
  const sliderID = useUUID()
  const helperTextID = useUUID()

  const hardcodedSteps = useMemo(() => {
    if (slider_data !== undefined && slider_data?.steps !== null) {
      const sliderDataSteps = slider_data?.steps
      const hardcodedSliderDataSteps: [string, number][] = Array.isArray(sliderDataSteps)
        ? sliderDataSteps.map(({ text, value }) => [text, value])
        : Object.entries(sliderDataSteps)
      return hardcodedSliderDataSteps
    } else {
      return Object.entries(stepObject)
    }
  }, [stepObject, slider_data])
  const hasHardcodedSteps = hardcodedSteps.length > 0

  const sliderDataHardcodeMin = slider_data?.min || hardcodeMin
  const sliderDataHardcodeMax = slider_data?.max || hardcodeMax

  const min = hasHardcodedSteps ? 0 : sliderDataHardcodeMin
  const max = hasHardcodedSteps ? hardcodedSteps.length - 1 : sliderDataHardcodeMax

  useEffect(() => {
    if (!getValues(name) && hookFormValue) setValue(name, hookFormValue)
  }, [getValues, hookFormValue, name, setValue])

  const showPlaceholder = hookFormValue === UNSET

  const minLabel = slider_data?.min_label || configMinLabel
  const maxLabel = slider_data?.max_label || configMaxLabel

  const minMaxLabelMarks =
    minLabel && maxLabel
      ? [
          { label: minLabel, value: min },
          { label: maxLabel, value: max },
        ]
      : undefined

  const [sliderValueOrStepsIndex, setSliderValueOrStepsIndex] = useState(() => {
    return calculateInitialSliderValueOrStepsIndex()
  })

  // Helpers
  // ---------------------------------------------------------------------------

  function calculateInitialSliderValueOrStepsIndex() {
    const defaultHalfwayPoint = min + (max - min) / 2

    if (showPlaceholder) return defaultHalfwayPoint
    const initialSliderValue = Number(hookFormValue)
    if (Number.isNaN(initialSliderValue)) {
      console.error(`Unable to convert \`defaultValue\` of \`${hookFormValue}\` to a number`)
      return defaultHalfwayPoint
    }

    if (!hasHardcodedSteps) return initialSliderValue

    const index = hardcodedSteps.findIndex(([, value]) => value === initialSliderValue)
    if (index === -1) return initialSliderValue
    return index
  }

  function calculateDataValue(newSliderValueOrStepsIndex: number): number {
    if (!hardcodedSteps?.[newSliderValueOrStepsIndex]) {
      return newSliderValueOrStepsIndex
    }
    const [, dataValue] = hardcodedSteps[newSliderValueOrStepsIndex]
    return dataValue
  }

  function labelFromDataValue(dataValue: number): string {
    if (showPlaceholder) return placeholder

    if (hardcodedSteps?.[sliderValueOrStepsIndex]) {
      const [label] = hardcodedSteps[sliderValueOrStepsIndex]
      return label
    }

    return dataValue.toString()
  }

  // Event Listeners
  // ---------------------------------------------------------------------------

  function onChange(
    event: Event,

    // MUISlider onChange supports range sliders, which can return a number[] array
    // See https://mui.com/material-ui/react-slider/#range-slider
    newSliderValueOrStepsIndex: number | number[],
  ) {
    if (typeof newSliderValueOrStepsIndex !== 'number') return

    setSliderValueOrStepsIndex(newSliderValueOrStepsIndex)

    hookFormOnChange({
      ...event,
      target: {
        ...event.target,
        value: calculateDataValue(newSliderValueOrStepsIndex),
      },
    })
  }

  // Render
  // ---------------------------------------------------------------------------

  return (
    <div className={styles.root}>
      <FormControl error={!!fieldState.error} fullWidth>
        <FormLabel
          className={clsx(styles.label, showPlaceholder && styles.placeholder)}
          htmlFor={sliderID}
        >
          {labelFromDataValue(calculateDataValue(sliderValueOrStepsIndex))}
        </FormLabel>
        <MUISlider
          name={name}
          componentsProps={{
            input: {
              ref: hookFormRef,
              id: sliderID,

              // HACK: ONLY adds this key if the conditions pass. We can’t fallback
              // to `undefined` as that would override MUI setting this for us.
              // See https://github.com/assuranceiq/react-plates/pull/75/files#r916082984
              ...(rules?.required && showPlaceholder && { 'aria-valuenow': 0 }),

              'aria-invalid': !!fieldState.error,
              'aria-errormessage': fieldState.error ? helperTextID : undefined,
              // NOTE: `aria-required` is not allowed for a `slider` role
            },
          }}
          marks={minMaxLabelMarks}
          min={min}
          max={max}
          step={step}
          value={sliderValueOrStepsIndex}
          aria-describedby={helperTextID}
          getAriaValueText={labelFromDataValue}
          valueLabelFormat={labelFromDataValue}
          scale={calculateDataValue}
          onBlur={hookFormOnBlur}
          onChange={onChange}
        />
        {fieldState.error && (
          <FormHelperText id={helperTextID} role='alert'>
            {fieldState.error?.message}
          </FormHelperText>
        )}
      </FormControl>
    </div>
  )
}
