import { useState } from 'react'

export const useInputState = () => {
  const [focused, setFocused] = useState<boolean>(false)
  const [hovered, setHovered] = useState<boolean>(false)

  const onFocus = () => setFocused(true)
  const onBlur = () => setFocused(false)

  const onMouseOver = () => setHovered(true)
  const onMouseOut = () => setHovered(false)

  return {
    focused,
    hovered,
    inputEventHandlers: {
      onBlur,
      onFocus,
      onMouseOver,
      onMouseOut,
    },
  }
}
