import { useEffect, useState } from 'react'

import { useStoreActions, useStoreState } from 'lib/store'

import styles from './DisclosureBanner.module.scss'

export const DisclosureBanner = ({ forceShow = false }) => {
  const disclosureBannerDisplayed = useStoreState(state => state.current.disclosureBannerDisplayed)
  const url = useStoreState(state => state.current.plate?.url)
  const setDisclosureBannerDisplayed = useStoreActions(
    actions => actions.current.setDisclosureBannerDisplayed,
  )
  const [firstUrl, setFirstUrl] = useState<string | undefined>(url)
  const [isShowing, setIsShowing] = useState<boolean>(
    forceShow || disclosureBannerDisplayed === undefined,
  )

  const stopShowing = () => {
    setIsShowing(false)
  }

  useEffect(() => {
    if (!isShowing) {
      return
    }
    if (!firstUrl && url) {
      setFirstUrl(url)
      return
    }
    if (url === firstUrl) {
      setDisclosureBannerDisplayed()
    } else {
      stopShowing()
    }
  }, [url, firstUrl, isShowing, setDisclosureBannerDisplayed])

  if (!isShowing) {
    return <></>
  }

  return (
    <div
      className={styles.popover}
      role='dialog'
      aria-labelledby='disclosureBannerTitle'
      aria-describedby='disclosureBannerDesc'
    >
      <div className={styles.popoverContext}>
        <div className={styles.close} onClick={stopShowing}></div>
        <div className={styles.title} id='disclosureBannerTitle'>
          Data collection consent
        </div>
        <div className={styles.text} id='disclosureBannerDesc'>
          By using this website, you consent to the real-time collection, storage, use, and share of
          information on your device, or provided by you (such as mouse movements and clicks) for
          Assurance IQ, LLC and/or its third-party providers. See our&nbsp;
          <a href='/privacy-center'>Privacy Center</a> for more information.
        </div>
      </div>
    </div>
  )
}
