import { FC, memo, useEffect, useRef, useState } from 'react'

import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import clsx from 'clsx'
import { useFormContext } from 'react-hook-form'

import { SanitizedHTML } from 'components/SanitizedHTML'
import { MAIN_CONTAINER_ID } from 'layouts/BaseLayout'
import {
  ButtonComponentProps,
  NextButtonWithTcpaConfig,
} from 'types/simple-form-components/button-components'

import styles from './NextButtonWithTcpaDisclosure.module.scss'

const AGREEMENT_ID = 'continue-button-agreement'
const DISCLOSURE_ID = 'continue-button-disclosure'
const SUPER_TEXT_ID = 'continue-button-super-text'
const TCPA_ID = 'leadid_tcpa_disclosure'

// HACK: Jornaya requires a label element surrounding all disclosure text for tracking. It’s not ideal.
// SEE: https://support.jornaya.com/s/article/How-do-I-add-Jornaya-TCPA-labels-tags-to-my-site
// SEE: https://assuranceiq.atlassian.net/browse/FE-243
const TcpaConsentTrackingWrapper: FC = ({ children }) => (
  <>
    <input type='hidden' id={TCPA_ID} />
    <label htmlFor={TCPA_ID}>{children}</label>
  </>
)

export interface TcpaDisclosureProps {
  disclosure?: string
}

export const TcpaDisclosure = memo(({ disclosure }: TcpaDisclosureProps) => {
  const disclosureVerbiageRef = useRef<HTMLButtonElement>(null)
  const [initBottomPosition, setInitBottomPosition] = useState<number>(0)

  // Tracking height of the main element during resizing viewport
  useEffect(() => {
    const mainComponent = document.getElementById(MAIN_CONTAINER_ID)
    if (!mainComponent) return

    const observer = new ResizeObserver(() => {
      const mainComponent = document.getElementById(MAIN_CONTAINER_ID)

      if (!mainComponent || !disclosureVerbiageRef.current) {
        return
      }

      // getComputedStyle should return paddingBottom from CSS not from style attribute
      mainComponent.style.paddingBottom = ''
      const initPaddingBottom = parseInt(window.getComputedStyle(mainComponent).paddingBottom)

      const initMarginBottom = parseInt(window.getComputedStyle(mainComponent).marginBottom)
      setInitBottomPosition(
        mainComponent.offsetTop + mainComponent.offsetHeight - initPaddingBottom - initMarginBottom,
      )

      // Increase paddingBottom on height of the disclosure
      const modifiedClientHeight =
        initPaddingBottom + disclosureVerbiageRef.current?.getBoundingClientRect().height

      mainComponent.style.paddingBottom = `${modifiedClientHeight}px`
    })

    observer.observe(mainComponent)

    return () => {
      // clean up
      mainComponent.style.paddingBottom = ''
      observer.disconnect()
    }
  }, [])

  if (!disclosure) return null

  return (
    <Grid item className={styles.disclosurePaper} style={{ top: initBottomPosition }}>
      <Typography
        className={styles.disclosure}
        gutterBottom
        ref={disclosureVerbiageRef}
        id={DISCLOSURE_ID}
      >
        <SanitizedHTML dirtyHtml={disclosure} />
      </Typography>
    </Grid>
  )
})

TcpaDisclosure.displayName = 'TcpaDisclosure'

export const NextButtonWithTcpaDisclosure = ({
  buttonProps: { 'aria-describedby': ariaDescribedBy, ...otherBaseProps } = {},
  children,
  options: { disclosure, super_text, text = 'Continue' } = {},
}: ButtonComponentProps<NextButtonWithTcpaConfig>) => {
  const buttonRef = useRef<HTMLButtonElement>(null)
  const formContext = useFormContext()

  useEffect(() => {
    const form = buttonRef.current?.closest('form')
    if (!form || !buttonRef.current) {
      return
    }
    function preventKeyboardSubmit(ev: KeyboardEvent) {
      // enter key
      if (ev.which === 13 && document.activeElement !== buttonRef.current) {
        ev.preventDefault()
        if (formContext?.formState?.isValid) {
          const target = ev.target as HTMLElement
          target?.blur()
          buttonRef.current?.focus()
        }
      }
    }
    form.addEventListener('keypress', preventKeyboardSubmit)

    return () => {
      form.removeEventListener('keypress', preventKeyboardSubmit)
    }
  }, [buttonRef, formContext])

  return (
    <TcpaConsentTrackingWrapper>
      <Grid container justifyContent='center'>
        <Grid item xs={12} md={6} lg={12}>
          {super_text && (
            <Typography
              className={styles.superText}
              gutterBottom
              id={SUPER_TEXT_ID}
              // FIXME: investigate how to exclude dangerouslySetInnerHTML or sanitize the value
              dangerouslySetInnerHTML={{ __html: super_text }}
            />
          )}
          <Typography className={styles.agreement} gutterBottom id={AGREEMENT_ID}>
            By clicking the “{text}” Button, I agree to the consents below the button.
          </Typography>
          <Button
            ref={buttonRef}
            aria-describedby={clsx(ariaDescribedBy, SUPER_TEXT_ID, AGREEMENT_ID, DISCLOSURE_ID)}
            {...otherBaseProps}
          >
            {children}
          </Button>
        </Grid>
        {disclosure && <TcpaDisclosure disclosure={disclosure} />}
      </Grid>
    </TcpaConsentTrackingWrapper>
  )
}
