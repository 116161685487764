import { Suspense, useEffect } from 'react'

import Alert from '@mui/material/Alert'

import { Loading } from '@assuranceiq/react-components/components/Loading'

import { useStoreState } from 'lib/store'
import { lazyPlates } from 'plates/lazy-index'
import { LazyPlateComponent } from 'types/plate'
import { logError } from 'utils/datadog'

const CompactLoader = () => <Loading justifyContent='center' minHeight='200' />
const FullScreenLoader = () => <Loading fullscreen />

export function PlateLoader({ inModal }: { inModal?: boolean }) {
  const plate = useStoreState(state => (inModal ? state.current.modal.plate : state.current.plate))
  const field_data = useStoreState(state =>
    inModal ? state.current.modal.field_data : state.current.field_data,
  )
  const service_data = useStoreState(state =>
    inModal ? state.current.modal.service_data : state.current.service_data,
  )

  useEffect(() => {
    if (!plate || inModal) return

    // Scroll to top of page on every new plate
    document.body.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }, [plate, inModal])

  if (!plate) return inModal ? <CompactLoader /> : <FullScreenLoader />

  const LazyPlate = lazyPlates[plate.component] as LazyPlateComponent

  if (plate && !LazyPlate) {
    logError(`CRITICAL ERROR! Unrecognized plate type: ${plate.component}`)

    if (process.env.NODE_ENV !== 'development') return <FullScreenLoader />

    return (
      <div>
        <Alert severity='error'>
          <strong>Unrecognized plate:</strong> {plate.component}
        </Alert>
        <pre>{JSON.stringify(plate.component_options, null, 2)}</pre>
      </div>
    )
  }

  return (
    <Suspense fallback={<CompactLoader />}>
      <LazyPlate
        key={plate?.url}
        options={plate.component_options}
        field_data={field_data}
        service_data={service_data}
      />
    </Suspense>
  )
}
