import InputAdornment from '@mui/material/InputAdornment'
import Typography from '@mui/material/Typography'

import {
  PhoneConfig,
  SimpleFormComponentProps,
} from 'types/simple-form-components/input-components'

import { Input } from '../Input'

import styles from './CurrencyInput.module.scss'

export type CurrencyInputProps = SimpleFormComponentProps<PhoneConfig>

export const CurrencyInput = ({
  attrs,
  field,
  placeholder = 'Enter Value',
  mask_options,
  ...inputProps
}: CurrencyInputProps) => {
  return (
    <Input
      {...inputProps}
      attrs={{
        inputMode: 'numeric',
        ...attrs,
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <Typography className={styles.dollarSign} color='primary'>
              $
            </Typography>
          </InputAdornment>
        ),
      }}
      field={field}
      // SEE: https://imask.js.org/guide.html#masked-number
      mask_options={{
        mask: 'IMask.Number',
        signed: false,
        thousandsSeparator: ',',
        ...mask_options,
      }}
      placeholder={placeholder}
    />
  )
}
