import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import FormLabel from '@mui/material/FormLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'

import clsx from 'clsx'
import { UseControllerProps, useFormContext } from 'react-hook-form'

import { useFieldRules } from 'hooks/useFieldRules'
import { useUUID } from 'hooks/useUUID'
import {
  RadioButtonsConfig,
  SimpleFormComponentProps,
} from 'types/simple-form-components/input-components'

import { FormControlLabel } from '../FormControlLabel'
import { useController } from '../FormProvider'
import { InputFieldValues } from '../Input'

import styles from './RadioButtons.module.scss'

export type RadioButtonsProps = SimpleFormComponentProps<RadioButtonsConfig>

export const RadioButtons = ({
  attrs: { 'aria-labelledby': ariaLabelledBy, className, ...attrs } = {},
  field: name,
  label,
  options = [],
  rules: defaultRules,
  validateField,
  validation,
  value,
}: RadioButtonsProps) => {
  const { control } = useFormContext()
  const rules = useFieldRules({ defaultRules, validateField, field: name, validation })
  const {
    field: { ref, ...field },
    fieldState,
  } = useController({
    name,
    control,
    defaultValue: value ?? '', // Can not be undefined
    rules,
  } as UseControllerProps<InputFieldValues>)

  const labelId = useUUID()
  const helperTextID = useUUID()

  return (
    <FormControl className={styles.root} fullWidth>
      <div className={styles.subtextContainer}>Select one</div>
      {label && (
        <FormLabel className={styles.label} id={labelId} error={!!fieldState.error}>
          {label}
        </FormLabel>
      )}
      <RadioGroup
        aria-describedby={fieldState.error ? undefined : helperTextID}
        aria-errormessage={fieldState.error ? helperTextID : undefined}
        aria-invalid={!!fieldState.error}
        aria-required={!!rules?.required}
        aria-labelledby={label ? labelId : ariaLabelledBy}
        className={clsx(styles.radioGroup, className)}
        {...field}
        {...attrs}
      >
        {options.map(option => (
          <FormControlLabel
            key={option}
            value={option}
            label={option}
            checked={option === field.value}
            error={!!fieldState.error}
            control={<Radio />}
          />
        ))}
      </RadioGroup>
      {fieldState.error?.message && (
        <FormHelperText className={styles.error} id={helperTextID}>
          {fieldState.error?.message}
        </FormHelperText>
      )}
    </FormControl>
  )
}
