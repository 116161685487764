import { AxiosInstance } from 'axios'
import { createStore, createTypedHooks, EasyPeasyConfig, State } from 'easy-peasy'

import { platesAPI } from 'lib/platesAPI/platesAPI'

import { currentModel, CurrentModel } from './models/current-model'
import { plateModel, PlateModel } from './models/plate-model'

export interface Injections {
  platesAPI: AxiosInstance
}

export interface StoreModel {
  current: CurrentModel
  plate: PlateModel
}

export type StoreState = State<StoreModel>
export type ReactPlatesStoreConfig = EasyPeasyConfig<StoreState, Injections>

export const model: StoreModel = {
  current: currentModel,
  plate: plateModel,
}

/**
 * Useful for creating a mocked store with custom injections or mockActions enabled
 *
 * @see https://easy-peasy.dev/docs/api/store-config.html#standard-configuration
 * @see https://easy-peasy.dev/docs/tutorials/testing.html#strategy-1-mocking-actions
 * @see https://easy-peasy.dev/docs/tutorials/testing.html#mocking-calls-to-services
 */
export const createReactPlatesStore = (config: ReactPlatesStoreConfig = {}) =>
  createStore<StoreModel, StoreState, Injections>(model, {
    ...config,

    injections: {
      platesAPI,

      ...config.injections,
    },
  })

export const store = createReactPlatesStore()

export const { useStore, useStoreActions, useStoreDispatch, useStoreState } =
  createTypedHooks<StoreModel>()
