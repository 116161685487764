import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react'

import { useFormContext } from 'react-hook-form'

interface FieldProps {
  isValid: boolean
}

interface FieldsProps {
  [key: string]: FieldProps
}

interface UseValidationProps {
  defaultValue: string
  name: string

  /**
   * If true, the validation will be triggered on blur
   * @default false
   */
  enableForceValidation?: boolean
}

interface ValidationContextProps {
  fields: FieldsProps
  setFields: React.Dispatch<ValidationContextProps['fields']>
}

const ValidationContext = createContext<ValidationContextProps | null>(null)
ValidationContext.displayName = 'ValidationContext'

/**
 * useValidation is a hook that adds a field to the validation context.
 */
export const useValidation = ({
  enableForceValidation = false,
  name,
  defaultValue,
}: UseValidationProps) => {
  const [forceValidation, setForceValidatation] = useState<boolean>(!!defaultValue)
  const context = useContext(ValidationContext)

  if (!context) {
    throw new Error('useValidation must be used within a Validation')
  }

  const { fields, setFields } = context

  const field = fields[name] ? fields[name] : undefined

  const { trigger: triggerValidation, watch, getFieldState } = useFormContext()
  const fieldValue = watch(name)
  const fieldState = getFieldState(name)

  const onBlur = () => {
    if (!enableForceValidation || forceValidation || !fieldValue) return

    setForceValidatation(true)
  }

  useEffect(() => {
    if (!enableForceValidation || !fieldValue || !forceValidation) return

    // force react-hook-form validation and update the error state
    triggerValidation(name)
  }, [fieldValue, name, triggerValidation, forceValidation, enableForceValidation])

  useEffect(() => {
    const isValid = !fieldState.error && forceValidation && !!fieldValue

    if (fields[name] && fields[name].isValid === isValid) return

    setFields({
      ...fields,
      [name]: { isValid },
    })
  }, [fieldState.error, fieldValue, fields, name, setFields, forceValidation])

  return { field, onBlur }
}

/**
 * useValidationContext is a hook that allows you to get the validation state of a field
 */
export const useValidationContext = (name?: string) => {
  const context = useContext(ValidationContext)

  if (!context) {
    throw new Error('useValidationContext must be used within a Validation')
  }

  const field = name && context.fields[name] ? context.fields[name] : undefined

  return { field, fields: context.fields }
}

export interface ValidationProps extends PropsWithChildren<unknown> {
  defaultState?: FieldsProps
}

/**
 * Validation is an extension of react-hook-form that gets additional properties
 */
export const Validation = ({ defaultState = {}, ...props }: ValidationProps) => {
  const [fields, setFields] = useState<FieldsProps>(defaultState)

  return <ValidationContext.Provider value={{ fields, setFields }} {...props} />
}
