import IMask from 'imask'

import { InputMaskOptions } from 'types/simple-form-components/input-components'

interface MergeMaskAndRegexProps {
  mask?: InputMaskOptions['mask']
  maskRegexString?: string
}

const mergeMaskAndRegex = ({ mask, maskRegexString }: MergeMaskAndRegexProps) => {
  if (mask) {
    return getMaskType(mask)
  }

  if (maskRegexString) {
    return new RegExp(maskRegexString)
  }
}

const getMaskType = (mask: InputMaskOptions['mask']) => {
  switch (mask) {
    case 'IMask.MaskedRange':
      return IMask.MaskedRange
    case 'IMask.Number':
      return Number
    default:
      return mask
  }
}

const getMaskBlocks = (blocks: NonNullable<InputMaskOptions['blocks']>) => {
  return Object.keys(blocks).reduce((acc = {}, key) => {
    const { mask, maskRegexString, ...otherProps } = blocks[key]

    acc[key] = {
      ...otherProps,

      // @ts-ignore get around the fact that the mask prop might be a string, class, or regex
      mask: mergeMaskAndRegex({ mask, maskRegexString }),
    }
    return acc
  }, {} as InputMaskOptions['blocks'])
}

export const convertToIMaskOptions = ({
  mask: maskConfig,
  maskRegexString,
  requiredNumberLength,
  blocks,
  ...otherProps
}: InputMaskOptions): IMask.AnyMaskedOptions | undefined => {
  const mask = mergeMaskAndRegex({ mask: maskConfig, maskRegexString })

  if (mask) {
    return {
      ...otherProps,
      blocks: blocks && getMaskBlocks(blocks),

      // @ts-ignore get around the fact that the mask prop might be a string, class, or regex
      mask,
    }
  }
}

type InputMaskOptionsKeys =
  | keyof Omit<InputMaskOptions, 'blocks'>
  | keyof InputMaskOptions['blocks']

const getMaskOptionsProperty = (maskOptions: InputMaskOptions, property: InputMaskOptionsKeys) => {
  const { blocks = {}, ...otherOptions } = maskOptions

  const inputs = [otherOptions, ...Object.values(blocks)]

  let propertyValue
  inputs.forEach(block => {
    if (typeof block[property] === 'number') {
      propertyValue = block[property]
      return
    }
  })

  return propertyValue
}

export const getMaskMaxValue = (maskOptions: InputMaskOptions) => {
  const max = getMaskOptionsProperty(maskOptions, 'max')
  const to = getMaskOptionsProperty(maskOptions, 'to')

  return max || to
}
