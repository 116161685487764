import { FC } from 'react'

import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'

import clsx from 'clsx'

import { TrustedSite } from 'components/TrustedSite'
import { useFeatureFlag } from 'hooks/useFeatureFlag'
import { BaseLayout } from 'layouts/BaseLayout'
import { Link } from 'layouts/BaseLayout/BaseFooter'
import { useStoreState } from 'lib/store'

import { ReactComponent as BBBLogo } from 'images/icons/bbb-logo.svg'

import styles from './LifeLayout.module.scss'

const HeroHeadingContainer = () => {
  const plate = useStoreState(state => state.current.plate)
  const path = useStoreState(state => state.current.path)
  const salesPath = plate?.sales_path
  const heroHeading = plate?.component_options?.hero_heading
  const showFinalExpenseFeatureFlag =
    useFeatureFlag('life_test_rp_final_expense_plex3124') === 'show'
  let text = 'Get a free life insurance quote'
  let subText = 'with no medical exam.'

  if (salesPath === 'digital') {
    text = 'No Medical Exam Life Insurance Rates!'
    subText = ''
  }

  if (showFinalExpenseFeatureFlag && path?.name?.startsWith('life/final_expense')) {
    text = 'Leaving a legacy'
    subText = 'Final expense life insurance'
    if (heroHeading) {
      text = heroHeading?.text ? heroHeading.text : ''
      subText = heroHeading?.sub_text ? heroHeading.sub_text : ''
    }
  }

  return (
    <Container className={clsx(styles.heading, subText ? styles.withSubText : '')}>
      <Typography className={styles.text} component='h1' variant='h3' gutterBottom>
        {text}
      </Typography>
      {subText && (
        <Typography className={styles.subText} component='h2' variant='h4' gutterBottom>
          {subText}
        </Typography>
      )}
    </Container>
  )
}

const ROID = 'RO3200526'

export const DEFAULT_LIFE_PHONE = '8333244561'
export const DEFAULT_LIFE_PHONE_FORMATTED = '(833) 324-4561'

const PlatesFooterText = () => (
  <>
    <p>
      Assurance IQ, LLC, a licensed agency and does business as Assurance, only where licensed and
      appointed. Assurance’s license information can be found{' '}
      <Link url='https://assurance.com/licenses' title='here' />. Assurance IQ, LLC is a wholly
      owned subsidiary of Prudential Financial, Inc. Assurance is not affiliated or connected with
      any government program or agency.
    </p>
    <Typography variant='inherit'>
      Assurance IQ is not an insurer. The insurance company determines whether you will be approved
      for coverage and any premium rating you will be offered. There is no guarantee that you will
      qualify for the advertised savings, rates, fees or terms shown. Underwriting decisions vary
      based upon the product applied for, your health and other factors determined by the insurers.
      Rates, fees and other terms are not guaranteed, unless indicated otherwise, and may change.
    </Typography>
    {/*TODO: add those disclosures back when we get rid of landing pages, only for non-results pages */}
    {/*<Typography variant='inherit'>*/}
    {/*  <i>Quotes:</i> Getting a quote provides an estimate of the cost of an insurance policy but it*/}
    {/*  does not mean you have insurance coverage. Depending on the type of policy you select, you may*/}
    {/*  need to provide additional information before the insurance company makes a final decisions*/}
    {/*  about the insurance and cost of coverage.*/}
    {/*</Typography>*/}
    {/*<Typography variant='inherit'>*/}
    {/*  <i>Advertised Premiums:</i> Actual policy premiums will vary based on product and individual*/}
    {/*  factors such as product types offered by Assurance IQ, product availability by state, term,*/}
    {/*  face amount, and the applicants age, sex, tobacco use, and health history. See additional*/}
    {/*  information about advertised rates{' '}*/}
    {/*  <Link url='https://assurance.com/landing/advertising-disclosures' title='here' />*/}
    {/*</Typography>*/}
    <Typography variant='inherit'>
      <i>No Medical Exam Life Insurance:</i> Issuance of the policy may depend upon the answers to
      the health questions set forth in the application.
    </Typography>
    {/*<Typography variant='inherit'>*/}
    {/*  <i>Simplified Issue Products:</i> There are many types of life insurance policies, each with*/}
    {/*  its own benefits and considerations. Simplified issue policies can be quick and convenient,*/}
    {/*  but other types of policies may be less expensive. You should consider what type of policy*/}
    {/*  best meets your needs and talk with a licensed insurance agent if you have questions.*/}
    {/*</Typography>*/}
  </>
)

const ResultsFooterText = () => (
  <p>
    We strive to provide a wide array of offers. However, the offers on this site are limited to
    insurance companies and agencies from which the operator receives compensation. The order is
    determined based on internal models using a combination of the information provided by the user,
    advertised insurance companies and agencies, 3rd party data sources, and internal data on other
    users’ experiences. Offers that are advertisements are not actual quotes. The placement and
    order should not be considered an endorsement of any specific offer. The information, insurance
    companies and agencies which appear on this site are subject to change at any time.
  </p>
)

export const LifeLayout: FC = ({ children }) => {
  const plate = useStoreState(state => state.current.plate)
  const currentStep = useStoreState(state => state.current.path?.current_step)
  const isResultsPlate = plate?.component === 'Results'
  const tty = useStoreState(state => state.current.layout?.options.tty)
  const greenBannerFeatureFlag = useFeatureFlag('life_test_rp_green_banner_plex3154')
  // TODO this is tmp fix until we fix virtual plates indexing
  const showAdDisclosureLink = currentStep === 0 || plate?.name === 'existing_policy'

  const isTobaccoUser = plate?.component_options?.tobacco
  const isGenderPlate = plate?.component_options?.is_gender_plate
  const isMarriagePlate = plate?.component_options?.is_marriage_plate
  const genderOrMarriage = isGenderPlate || isMarriagePlate
  let bannerTitle, bannerSubtitle
  if (isGenderPlate) {
    bannerTitle = "You're in the right spot!"
    bannerSubtitle =
      'Every month, over 10,000 people trust Assurance IQ to find the right life insurance policy.'
  } else if (isMarriagePlate) {
    bannerTitle = ''
    bannerSubtitle = isTobaccoUser
      ? 'We have affordable life insurance options that are a great fit for a tobacco users.'
      : 'As a non-smoker you may qualify for our lowest rates.'
  }
  let greenBannerProps = undefined
  if (genderOrMarriage && greenBannerFeatureFlag === 'show') {
    greenBannerProps = {
      title: bannerTitle,
      subtitle: bannerSubtitle,
    }
  }

  return (
    <BaseLayout
      footerProps={{
        mainText: (
          <>
            {isResultsPlate ? <ResultsFooterText /> : <PlatesFooterText />}
            <Typography variant='inherit'>
              This site is protected by reCAPTCHA and the Google{' '}
              <Link url='https://policies.google.com/privacy' title='Privacy Policy' /> and{' '}
              <Link url='https://policies.google.com/terms' title='Terms of Service' /> apply.
            </Typography>
          </>
        ),
        redOakId: ROID,
        agentCallHours: ['Mon - Sun 8am - 9pm EST'],
        widgets: (
          <>
            <BBBLogo />
            <TrustedSite />
          </>
        ),
        tty,
      }}
      headerProps={{
        showAdDisclosureLink,
        adDisclosureLink: 'https://assurance.com/landing/advertising-disclosures',
        agentCallHours: ['Mon - Sun 8am - 9pm EST'],
        agentPhone: DEFAULT_LIFE_PHONE,
        agentPhoneFormatted: DEFAULT_LIFE_PHONE_FORMATTED,
        tty,
      }}
      heroContainer={isResultsPlate ? '' : <HeroHeadingContainer />}
      className={styles.root}
      greenBanner={greenBannerProps}
    >
      {children}
    </BaseLayout>
  )
}
