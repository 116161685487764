import { useEffect } from 'react'

import Alert from '@mui/material/Alert'

import { SnackbarProvider } from '@assuranceiq/react-components/hooks/useSnackbar'
import { AssuranceThemeProvider } from '@assuranceiq/react-components/styles/AssuranceThemeProvider'

import { StoreProvider } from 'easy-peasy'

import { ActionTracker } from 'components/ActionTracker'
import { AfterFooterEmptySpace } from 'components/AfterFooterEmptySpace'
import { CallSchedulingModal } from 'components/CallSchedulingModal'
import { DatadogErrorBoundary } from 'components/DatadogErrorBoundary/DatadogErrorBoundary'
import { DataDogGlobalContext } from 'components/DataDogGlobalContext'
import { DisclosureBanner } from 'components/DisclosureBanner'
import { DomainBranding } from 'components/DomainBranding'
import { GoogleTagManager } from 'components/GoogleTagManager'
import { GPCBanner } from 'components/GPCBanner'
import { PlatesPresenter } from 'components/PlatesPresenter'
import { UpdateClient } from 'components/UpdateClient'
import { VirtualRouter } from 'components/VirtualRouter'
import { useLoad3rdPartyScript } from 'hooks/useLoad3rdPartyScript'
import { store } from 'lib/store'
import { theme } from 'styles/theme'
import { DD_ALLOWED_TRACING_ORIGINS } from 'utils/constants'
import { getDDRUM, logError } from 'utils/datadog'
import { JORNAYA_CAMPAIGN_KEY } from 'utils/environment-variables'
import {
  DD_APPLICATION_ID,
  DD_CLIENT_TOKEN,
  DD_SERVICE,
  DD_SESSION_REPLAY_SAMPLE_RATE,
  DD_SESSION_SAMPLE_RATE,
  DD_VERSION,
  DEPLOY_ENV,
} from 'utils/environment-variables'

import './App.scss'

export const App = () => {
  useLoad3rdPartyScript({
    id: 'trustedform',
    src: `//api.trustedform.com/trustedform.js?provide_referrer=false&field=xxTrustedFormCertUrl&l=${
      new Date().getTime() + Math.random()
    }&invert_field_sensitivity=false`,
  })
  useLoad3rdPartyScript({
    id: 'jornaya-campaign',
    src: `//create.lidstatic.com/campaign/${JORNAYA_CAMPAIGN_KEY}.js?snippet_version=2`,
  })
  const { appended: datadogAppended } = useLoad3rdPartyScript({
    id: 'datadog-rum',
    src: 'https://www.datadoghq-browser-agent.com/us1/v5/datadog-rum.js',
    loadIf: () => {
      let currentValue
      const value = `; ${document.cookie}`
      const parts = value.split('; _dd_s=')
      if (parts.length === 2) currentValue = (parts.pop() as string).split(';').shift()

      // Only load DataDog if user was not already bucketed into do-not-sample group
      return !currentValue || currentValue.indexOf('rum=0') === -1
    },
  })

  function errorFallback(error: Error) {
    logError(error)
    return (
      <div>
        <Alert severity='error'>
          <strong>Unexpected error happened. Try reloading the page</strong>
        </Alert>
      </div>
    )
  }

  useEffect(() => {
    if (!datadogAppended) return
    const isDentalFlow = window.location.pathname.includes('rp/dental')

    getDDRUM().onReady(() => {
      const DD_RUM = getDDRUM()
      const defaultFields = [
        'offer_id',
        'transaction_id',
        'utm_source',
        'utm_medium',
        'utm_campaign',
        'utm_content',
        'utm_term',
        'affiliate_behavior',
        'gclid',
        'is_test',
      ]
      const searchParams = new URLSearchParams(window.location.search)
      searchParams.delete('format')
      const utmData = defaultFields.reduce<Record<string, string | null>>((acc, key) => {
        if (searchParams.has(key)) {
          acc[key] = searchParams.get(key)
        }
        return acc
      }, {})

      const ddConfig = {
        applicationId: DD_APPLICATION_ID,
        clientToken: DD_CLIENT_TOKEN,
        service: DD_SERVICE,
        allowedTracingOrigins: DD_ALLOWED_TRACING_ORIGINS,
        env: DEPLOY_ENV,
        sessionSampleRate: DD_SESSION_SAMPLE_RATE,
        // HACK: Temporarily setting sessionReplaySampleRate to 100% for dental flow
        sessionReplaySampleRate: isDentalFlow ? 100 : DD_SESSION_REPLAY_SAMPLE_RATE,
        trackResources: true,
        trackLongTasks: true,
        trackInteractions: true,
        trackFrustrations: true,
        defaultPrivacyLevel: 'mask-user-input',
        version: DD_VERSION,
        enableExperimentalFeatures: ['feature_flags'],
      }

      DD_RUM.init(ddConfig)

      DD_RUM.setGlobalContextProperty('utm', utmData)

      DD_RUM.startSessionReplayRecording()
    })
  }, [datadogAppended])

  return (
    <DatadogErrorBoundary fallback={errorFallback}>
      <SnackbarProvider anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <StoreProvider store={store}>
          <GPCBanner />
          <DomainBranding />
          <VirtualRouter />
          <GoogleTagManager />
          <DataDogGlobalContext />
          <UpdateClient />
          <AssuranceThemeProvider theme={theme}>
            <PlatesPresenter />
            <DisclosureBanner />
            <AfterFooterEmptySpace />
            <CallSchedulingModal />
          </AssuranceThemeProvider>
          <ActionTracker />
        </StoreProvider>
      </SnackbarProvider>
    </DatadogErrorBoundary>
  )
}
