import Box from '@mui/material/Box'

import clsx from 'clsx'

import { useRenderNextButtonAtBottom } from 'hooks/useRenderNextButtonAtBottom'
import { useStoreState } from 'lib/store'

import styles from './AfterFooterEmptySpace.module.scss'

/*
 * This is an ugly hack to make sure that when Next button is rendered on the bottom of the page
 * (all SimpleForm plates except where next_button config is provided) it doesn't cover footer
 * content. So we reserve empty space at the bottom of the page for that.
 */
export const AfterFooterEmptySpace = () => {
  const isPlatesBottomNavigation = useStoreState(state => state.current.platesBottomNavigation)
  const isNextButtonAtBottom = useRenderNextButtonAtBottom()

  const show = isPlatesBottomNavigation || isNextButtonAtBottom

  if (!show) return null

  return (
    <Box className={clsx(styles.root, isPlatesBottomNavigation && styles.platesBottomNavigation)} />
  )
}
