import { useEffect, useMemo, useRef } from 'react'

import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'

import clsx from 'clsx'
import { useForm } from 'react-hook-form'

import { Question } from 'components/Question'
import Checkboxes from 'components/SimpleForm/Checkboxes'
import CurrencyInput from 'components/SimpleForm/CurrencyInput'
import DentalPlansList from 'components/SimpleForm/DentalPlansList'
import Disclosure from 'components/SimpleForm/Disclosure'
import EmbedElement from 'components/SimpleForm/EmbedElement'
import { FocusSwitcher } from 'components/SimpleForm/FocusSwitcher'
import { FormProvider } from 'components/SimpleForm/FormProvider'
import HiddenInput from 'components/SimpleForm/HiddenInput'
import { Input, InputProps } from 'components/SimpleForm/Input'
import MultiInput from 'components/SimpleForm/MultiInput'
import { NextButton } from 'components/SimpleForm/NextButton'
import Phone from 'components/SimpleForm/Phone'
import RadioButtons from 'components/SimpleForm/RadioButtons'
import RadioButtonsWithSubtext from 'components/SimpleForm/RadioButtonsWithSubtext'
import Select from 'components/SimpleForm/Select'
import Slider from 'components/SimpleForm/Slider'
import StateSelect from 'components/SimpleForm/StateSelect'
import Subtext from 'components/SimpleForm/Subtext'
import TcpaConsent from 'components/SimpleForm/TcpaConsent'
import Zip from 'components/SimpleForm/Zip'
import { useRenderNextButtonAtBottom } from 'hooks/useRenderNextButtonAtBottom'
import { useUUID } from 'hooks/useUUID'
import { useUUIDs } from 'hooks/useUUIDs'
import { useStoreActions, useStoreState } from 'lib/store'
import { PlateSubmissionData } from 'types/api'
import { PlateComponent, SimpleFormProps } from 'types/plate'
import { SimpleFormComponentProps } from 'types/simple-form-components/input-components'
import { logError } from 'utils/datadog'

import styles from './SimpleForm.module.scss'

const SIMPLE_FORM_COMPONENTS = {
  Checkboxes,
  CurrencyInput,
  EmbedElement,
  HiddenInput,
  Input,
  MultiInput,
  Phone,
  RadioButtons,
  RadioButtonsWithSubtext,
  Select,
  Slider,
  StateSelect,
  Zip,
  Disclosure,
  Subtext,
  DentalPlansList,
  TcpaConsent,
}

export let questionID = ''
const GENERATE_FIELD_PREFIX = 'generated_'

const removeGeneratedFields = (data: PlateSubmissionData) => {
  return Object.fromEntries(
    Object.entries(data).filter(([key]) => !key.startsWith(GENERATE_FIELD_PREFIX)),
  )
}

export const SimpleForm: PlateComponent<SimpleFormProps> = ({
  options: { components, next_button: nextButtonConfig, question },
  field_data,
  service_data,
}) => {
  const formRef = useRef<HTMLFormElement>(null)

  const methods = useForm({ defaultValues: field_data })
  const { getValues, handleSubmit, trigger } = methods

  const isPending = useStoreState(state => state.plate.isPending)
  const error = useStoreState(state => state.plate.data?.error?.field_error)
  const submit = useStoreActions(actions => actions.plate.submit)
  const outsideFormSubmitting = useStoreState(state => state.current.outsideFormSubmitting)
  const setOutsideFormSubmitting = useStoreActions(state => state.current.setOutsideFormSubmitting)

  const isModalPresenting = useStoreState(state => state.current.modalPlatesPresenting)
  const isPlatesBottomNavigation = useStoreState(state =>
    isModalPresenting
      ? state.current.modal.platesBottomNavigation
      : state.current.platesBottomNavigation,
  )

  useEffect(
    function submitFormFromOuside() {
      if (outsideFormSubmitting && formRef.current) {
        formRef.current.requestSubmit()
        setOutsideFormSubmitting(false)
      }
    },
    [outsideFormSubmitting, setOutsideFormSubmitting],
  )

  const buttonWithDisclosure =
    nextButtonConfig?.component === 'NextButtonWithTcpaDisclosure' ||
    nextButtonConfig?.component === 'NextButtonWithModal'

  const shouldRenderNextButtonAtBottom = useRenderNextButtonAtBottom()

  questionID = useUUID()
  const componentUUIDs = useUUIDs(components.length)

  const onSubmit = (data: PlateSubmissionData) => {
    if (isPending) return

    submit(removeGeneratedFields(data))
  }

  const serverValidationErrors = useMemo(
    () => (field: string) => () => {
      if (!error) return

      const fieldError = { ...error[field] }
      if (!fieldError || fieldError.value !== getValues(field)) return

      return fieldError.message
    },
    [error, getValues],
  )

  useEffect(() => {
    if (!error) return

    trigger() // trigger form validation if there’s a server validation error
  }, [error, trigger])

  return (
    <FormProvider {...methods}>
      <Box
        component='form'
        id='SimpleForm'
        className={styles.root}
        onSubmit={handleSubmit(onSubmit)}
        ref={formRef}
      >
        <Question id={questionID} question={question} />
        <Grid
          container
          justifyContent='center'
          gap={3}
          role='group'
          aria-describedby={questionID}
          className={clsx(buttonWithDisclosure ? '' : styles.inputContainer)}
        >
          <Grid item xs={12} sm={8} lg={8}>
            <FocusSwitcher>
              {components.map((config, index) => {
                const { component, field, ...props } = config
                const SimpleFormComponent =
                  SIMPLE_FORM_COMPONENTS[component] || UnknownInput(component)

                // If the component doesn't have a field, generate a unique one for react-hook-form
                const formField =
                  field || `${GENERATE_FIELD_PREFIX}-${component}-${componentUUIDs[index]}`

                return (
                  <SimpleFormComponent
                    // HACK: help TypeScript understand that it's right props
                    {...(props as SimpleFormComponentProps)}
                    attrs={{ 'aria-labelledby': questionID }}
                    key={formField}
                    field={formField}
                    validateField={serverValidationErrors}
                  />
                )
              })}
            </FocusSwitcher>
          </Grid>
        </Grid>
        {!isPlatesBottomNavigation && (
          <Grid
            container
            justifyContent='center'
            alignItems='center'
            gap={3}
            role='group'
            aria-describedby={questionID}
            data-testid='navigationButtonContainerId'
            className={clsx(
              shouldRenderNextButtonAtBottom
                ? styles.nextButtonContainerBottom
                : styles.nextButtonContainer,
            )}
          >
            <Grid
              item
              xs={12}
              sm={8}
              md={buttonWithDisclosure ? 8 : 4}
              className={styles.nextButtonWrapper}
            >
              <NextButton
                buttonProps={{
                  'aria-describedby': questionID,
                }}
                singleButton={true}
                isPending={isPending}
                nextButtonConfig={nextButtonConfig}
                service_data={service_data}
              />
            </Grid>
          </Grid>
        )}
      </Box>
    </FormProvider>
  )
}

// TODO: Delete UnknownInput once all inputs are built
// TODO: Remove or disable on production!
const UnknownInput = (component: string) => {
  logError(`CRITICAL ERROR! Unrecognized SimpleForm input: ${component}.`)

  if (process.env.NODE_ENV !== 'development') return null

  return function UnknownInput(props: InputProps) {
    return (
      <Box sx={{ mb: 2 }}>
        <Alert severity={'error'}>
          <strong>Unrecognized SimpleForm component:</strong> {component}
        </Alert>
        <Input {...props} />
        <Box component='pre' sx={{ textAlign: 'left' }}>
          {JSON.stringify(props, null, 2)}
        </Box>
      </Box>
    )
  }
}
