import { ReactNode, Suspense } from 'react'

import Alert from '@mui/material/Alert'

import { Loading } from '@assuranceiq/react-components/components/Loading'

import { lazyLayouts } from 'layouts/lazy-index'
import { useStoreState } from 'lib/store'
import { LazyLayoutComponent } from 'types/layout'
import { logError } from 'utils/datadog'

export interface LayoutLoaderProps {
  children?: ReactNode
}

export function LayoutLoader({ children }: LayoutLoaderProps) {
  const layout = useStoreState(state => state.current.layout)

  if (!layout) return <>{children}</>

  const LazyLayout = lazyLayouts[layout.component] as LazyLayoutComponent

  if (layout && !LazyLayout) {
    logError(`CRITICAL ERROR! Unrecognized layout: ${layout.component}.`)
    return (
      <div>
        <Alert severity='error'>
          <strong>Unrecognized layout:</strong> {layout.component}
        </Alert>
        <pre>{JSON.stringify(layout.options, null, 2)}</pre>
      </div>
    )
  }

  return (
    <Suspense fallback={<Loading fullscreen />}>
      <LazyLayout {...layout.options}>{children}</LazyLayout>
    </Suspense>
  )
}
