import Box from '@mui/material/Box'
import FormHelperText from '@mui/material/FormHelperText'

import { useFormContext } from 'react-hook-form'

import { useInputState } from 'hooks/useInputState'
import { useUUID } from 'hooks/useUUID'
import {
  MultiInputConfig,
  SimpleFormComponentProps,
} from 'types/simple-form-components/input-components'

import { InputBase } from '../Input'

import styles from './MultiInput.module.scss'

export interface MultiInputProps extends SimpleFormComponentProps<MultiInputConfig> {
  field: string
}

export const MultiInput = ({
  attrs: commonAttrs,
  label,
  inputs = [],
  ...otherProps
}: MultiInputProps) => {
  const labelID = useUUID()
  const { inputEventHandlers } = useInputState()

  const {
    formState: { errors },
  } = useFormContext()

  const firstErrorInput = inputs.find(input => errors[input.field])

  const errorTextID = useUUID()

  return (
    <div className={styles.root}>
      <div className={styles.inputsRow}>
        {inputs.map(
          ({ input_attrs: { 'aria-label': inputAriaLabel, ...inputAttrs } = {}, ...input }) => {
            return (
              <Box key={input.field} className={styles.input}>
                <InputBase
                  attrs={{
                    ...commonAttrs,
                    ...inputAttrs,
                    ...inputEventHandlers,
                    'aria-label': inputAriaLabel,
                    'aria-labelledby': inputAriaLabel ? undefined : labelID,
                    'aria-describedby': errors[input.field] ? undefined : errorTextID,
                    'aria-errormessage': errors[input.field] ? errorTextID : undefined,
                  }}
                  showErrorMessage={false}
                  InputProps={{ endAdornment: null, sx: { borderRadius: 0, border: 'none' } }}
                  {...otherProps}
                  {...input}
                />
              </Box>
            )
          },
        )}
      </div>
      {firstErrorInput && (
        <FormHelperText className={styles.error} id={errorTextID} role='alert'>
          {errors[firstErrorInput.field]?.message}
        </FormHelperText>
      )}
    </div>
  )
}
