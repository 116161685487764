import { useEffect } from 'react'

import TagManager from 'react-gtm-module'

import { DELAY_3RD_PARTY_SCRIPTS } from 'hooks/useLoad3rdPartyScript'
import getDomainBranding from 'lib/domain-branding'
import { useStoreState } from 'lib/store'

declare global {
  interface Window {
    gon?: object
    dataLayer?: object[]
    google_tag_manager?: object
  }
}

export const GoogleTagManager = () => {
  const { gtm } = getDomainBranding()
  const show_gtm = useStoreState(state => state.current.context_data?.show_gtm)
  const gon_data = useStoreState(state => state.current.service_data?.gon_data)
  const gtm_context = useStoreState(state => state.current.context_data?.gtm_context)

  useEffect(() => {
    if (!show_gtm) {
      return
    }
    if (!gtm) return
    if (window.google_tag_manager) return // GTM already initialized- this var is set by the gtm.js script

    // FIXME: Ignore GTM preview params until env-dependent domains available
    // const gtmParams = new URLSearchParams(gtm_preview)

    // delay GTM init to give chance for page scripts to finish execute and make LCP
    const timeoutId = setTimeout(() => {
      TagManager.initialize({
        gtmId: gtm,
        // preview: gtmParams.get('gtm_preview') ?? undefined,
        // auth: gtmParams.get('gtm_auth') ?? undefined,
      })
    }, DELAY_3RD_PARTY_SCRIPTS)

    return () => clearTimeout(timeoutId)
  }, [gtm, show_gtm])

  useEffect(() => {
    if (!gon_data) return

    window.gon = gon_data

    // Also push variable to data layer, to support deprecation of gon
    window.dataLayer ||= []
    window.dataLayer.push(gon_data)
  }, [gon_data])

  useEffect(() => {
    if (!gtm_context) return

    window.dataLayer ||= []
    window.dataLayer.push({
      ...gtm_context.event_data,
      event: gtm_context.event,
    })
  }, [gtm_context])

  return null
}
