import xss from 'xss'

const whiteListOptions = {
  whiteList: {
    b: [],
    br: [],
    p: [],
    i: [],
    small: [],
    a: ['href', 'target'],
    span: ['class'],
    ul: [],
    li: [],
  },
}
const sanitize = (dirty: string) => ({
  __html: xss(dirty, whiteListOptions),
})

export interface SanitizedHTMLProps {
  dirtyHtml: string
}

export function SanitizedHTML({ dirtyHtml }: SanitizedHTMLProps) {
  return <span dangerouslySetInnerHTML={sanitize(dirtyHtml)} />
}
