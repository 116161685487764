import { useEffect, useState } from 'react'

import Divider from '@mui/material/Divider'

import { ModalDialog } from 'components/ModalDialog'
import {
  PlatesBottomNavigation,
  useCleanUpPlatesBottomNavigation,
} from 'components/PlatesBottomNavigation'
import { PlatesPresenter } from 'components/PlatesPresenter'
import { useStoreActions, useStoreState } from 'lib/store'

import styles from './CallSchedulingModal.module.scss'

export const SCHEDULING_HASH = '#scheduling-modal'
const SCHEDULING_PLATE_URL = '/rp/call_scheduler'

export const CallSchedulingModal = () => {
  const [open, setOpen] = useState(false)
  const plate = useStoreState(state => state.current.plate)
  const setModalPlatesPresenting = useStoreActions(
    actions => actions.current.setModalPlatesPresenting,
  )

  useEffect(() => {
    const toggleModalBasedOnHash = () => {
      setOpen(window.location.hash === SCHEDULING_HASH)
    }

    toggleModalBasedOnHash()

    window.addEventListener('hashchange', toggleModalBasedOnHash)

    return () => {
      window.removeEventListener('hashchange', toggleModalBasedOnHash)
    }
  }, [])

  useEffect(() => {
    // HACK: We need to finish loading for plate under the modal first
    if (!plate) return

    setModalPlatesPresenting(open)
  }, [setModalPlatesPresenting, open, plate])

  useCleanUpPlatesBottomNavigation()

  const handleClose = () => {
    setOpen(false)
    window.history.pushState('', document.title, window.location.pathname + window.location.search)
  }

  return (
    <ModalDialog
      title='Schedule a call'
      fullWidth
      isOpened={open}
      className={styles.root}
      showCloseIcon={true}
      setIsOpened={setOpen}
      handleClose={handleClose}
      body={<PlatesPresenter modalStartingPlate={SCHEDULING_PLATE_URL} />}
      customActions={
        <>
          <Divider />
          <PlatesBottomNavigation className={styles.navigation} />
        </>
      }
    />
  )
}
