import { action, Action, actionOn, ActionOn } from 'easy-peasy'

import { StoreModel } from 'lib/store'
import { Context, ContextData, PreviousPlate, Retreaver } from 'types/api'
import { LayoutComponentName, LayoutConfig } from 'types/layout'
import { FieldData, PlateConfig, ServiceData } from 'types/plate'
import { detectLOB } from 'utils/detect-lob'

export interface CurrentModel {
  plate?: PlateConfig
  layout?: LayoutConfig
  path?: Context['path']

  context_data?: ContextData
  field_data?: FieldData
  service_data?: ServiceData
  previous_plate?: PreviousPlate
  retreaver?: Retreaver

  onNewPlate: ActionOn<CurrentModel, StoreModel>

  disclosureBannerDisplayed?: string
  setDisclosureBannerDisplayed: Action<CurrentModel>

  platesBottomNavigation: boolean
  setPlatesBottomNavigation: Action<CurrentModel, boolean>

  modalPlatesPresenting: boolean
  setModalPlatesPresenting: Action<CurrentModel, boolean>

  modal: {
    plate?: PlateConfig
    field_data?: FieldData
    service_data?: ServiceData
    previous_plate?: PreviousPlate
    platesBottomNavigation: boolean
  }

  outsideFormSubmitting: boolean
  setOutsideFormSubmitting: Action<CurrentModel, boolean>
}

const DUMMY_LAYOUT: Pick<LayoutConfig, 'options'> = {
  options: {
    animation: {
      appear: 'fadeInRight',
      disappear: 'fadeOutLeft',
    },
    reverse: { appear: 'fadeInLeft', disappear: 'fadeOutRight' },
    duration: '0.4s',
  },
}

const PRELOAD_LAYOUTS: Record<LayoutComponentName, LayoutConfig> = {
  Medicare: {
    ...DUMMY_LAYOUT,
    component: 'Medicare',
  },
  Life: {
    ...DUMMY_LAYOUT,
    component: 'Life',
  },
  Dental: {
    ...DUMMY_LAYOUT,
    component: 'Dental',
  },
}

const detectedLOB = detectLOB(window.location.pathname)

export const DISCLOSURE_BANNER_DISPLAY_TIME = 'disclosure-banner-display-time'

export const currentModel: CurrentModel = {
  plate: undefined,
  // Manually presetting the layout object to display it earlier and avoid flashing content
  layout: detectedLOB ? PRELOAD_LAYOUTS[detectedLOB] : undefined,
  path: undefined,

  context_data: undefined,
  field_data: undefined,
  service_data: undefined,

  platesBottomNavigation: false,
  setPlatesBottomNavigation: action((state, payload) => {
    if (state.modalPlatesPresenting) {
      state.modal.platesBottomNavigation = payload
    } else {
      state.platesBottomNavigation = payload
    }
  }),

  modalPlatesPresenting: false,
  setModalPlatesPresenting: action((state, payload) => {
    state.modalPlatesPresenting = payload
  }),

  // We need to dublicate those fields to load plates inside modals
  modal: {
    plate: undefined,
    field_data: undefined,
    service_data: undefined,
    platesBottomNavigation: false,
  },

  // HACK: This is a workaround to submit SimpleForm from outside of the form
  outsideFormSubmitting: false,
  setOutsideFormSubmitting: action((state, payload) => {
    state.outsideFormSubmitting = payload
  }),

  onNewPlate: actionOn(
    (_actions, storeActions) => storeActions.plate.resolved,
    (state, { payload: response }) => {
      if (!response.context) return

      if (state.modalPlatesPresenting) {
        state.modal.plate = response.context.plate
        state.modal.field_data = response.context.field_data
        state.modal.service_data = response.context.service_data
        state.modal.previous_plate = response.previous_plate
        return
      }

      state.plate = response.context.plate
      state.layout = response.context.path.layout
      state.path = response.context.path
      state.context_data = response.context.context_data
      state.field_data = response.context.field_data
      state.service_data = response.context.service_data
      state.previous_plate = response.previous_plate
      state.retreaver = response.retreaver
    },
  ),

  disclosureBannerDisplayed: sessionStorage?.getItem(DISCLOSURE_BANNER_DISPLAY_TIME) || undefined,
  setDisclosureBannerDisplayed: action(state => {
    if (state.disclosureBannerDisplayed || !sessionStorage) {
      // do not set value for second time
      return
    }
    const timestamp = new Date().getTime().toString()
    sessionStorage.setItem(DISCLOSURE_BANNER_DISPLAY_TIME, timestamp)
    state.disclosureBannerDisplayed = timestamp
  }),
}
