import Typography from '@mui/material/Typography'

import { SanitizedHTML } from '../../SanitizedHTML'

import styles from './Subtext.module.scss'

export interface SubtextProps {
  value: string
}

export const Subtext = ({ value }: SubtextProps) => {
  return (
    <Typography className={styles.subtext}>
      <SanitizedHTML dirtyHtml={value} />
    </Typography>
  )
}
