import { useMemo, useRef } from 'react'

import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import MUISelect from '@mui/material/Select'
import useMediaQuery from '@mui/material/useMediaQuery'

import { UseControllerProps, useFormContext } from 'react-hook-form'

import { useFieldRules } from 'hooks/useFieldRules'
import { useUUID } from 'hooks/useUUID'
import { ArrowButtonRightIcon } from 'icons'
import { theme } from 'styles/theme'
import {
  SelectConfig,
  SimpleFormComponentProps,
} from 'types/simple-form-components/input-components'

import { useFocusSwitcher } from '../FocusSwitcher'
import { useController } from '../FormProvider'
import { InputFieldValues } from '../Input'

import styles from './Select.module.scss'

export type SelectProps = SimpleFormComponentProps<SelectConfig>

export const Select = ({
  attrs: { 'aria-labelledby': ariaLabelledBy, ...attrs } = {},
  field: name = '',
  label,
  dropdown_options = {},
  placeholder,
  rules: defaultRules,
  validateField,
  validation,
  value,
  hide_default_option,
  ...stateFieldProps
}: SelectProps) => {
  const { control } = useFormContext()
  const rules = useFieldRules({ defaultRules, validateField, field: name, validation })
  const {
    field: { ref: controllerRef, ...field },
    fieldState,
  } = useController({
    name,
    control,
    defaultValue: value ?? '', // Can not be undefined
    rules,
  } as UseControllerProps<InputFieldValues>)

  const ref = useRef<HTMLSelectElement | null>(null)

  useFocusSwitcher({ name, fieldState, ref })
  const isMobileLayout = useMediaQuery(theme.breakpoints.down('md'))

  const labelId = useUUID()
  const helperTextID = useUUID()

  const options = useMemo(() => {
    if (!Array.isArray(dropdown_options)) return dropdown_options

    return dropdown_options.reduce((acc, option) => {
      acc[option] = option
      return acc
    }, {} as Record<string, string>)
  }, [dropdown_options])

  const Placeholder = () => (
    <div aria-placeholder={placeholder} className={styles.placeholder}>
      {placeholder}
    </div>
  )

  const handleDisplayValue = (selected: string) =>
    placeholder && !selected ? <Placeholder /> : options[selected]

  return (
    <FormControl className={styles.root} fullWidth>
      {label && (
        <InputLabel id={labelId} error={!!fieldState.error} shrink={hide_default_option}>
          {label}
        </InputLabel>
      )}
      <MUISelect
        {...stateFieldProps}
        {...field}
        IconComponent={() => <ArrowButtonRightIcon className={styles.arrowDown} />}
        className={styles.input}
        displayEmpty={hide_default_option || (!label && !!placeholder)}
        error={!!fieldState.error}
        inputRef={element => {
          ref.current = element && element.node

          controllerRef(element)
        }}
        notched={hide_default_option}
        label={label}
        native={isMobileLayout}
        labelId={label ? labelId : ariaLabelledBy}
        renderValue={handleDisplayValue}
        SelectDisplayProps={{
          ...attrs,
          role: 'combobox',
          'aria-describedby': fieldState.error ? undefined : helperTextID,
          'aria-errormessage': fieldState.error ? helperTextID : undefined,
          'aria-invalid': !!fieldState.error,

          'aria-required': !!rules?.required,
        }}
        MenuProps={{ className: styles.menu }}
      >
        {placeholder && (
          <MenuItem disabled value=''>
            <Placeholder />
          </MenuItem>
        )}
        {Object.entries(options).map(([value, displayName]) => (
          <MenuItem {...(isMobileLayout ? { component: 'option' } : {})} key={value} value={value}>
            {displayName}
          </MenuItem>
        ))}
      </MUISelect>
      {fieldState.error?.message && (
        <FormHelperText className={styles.error} id={helperTextID}>
          {fieldState.error?.message}
        </FormHelperText>
      )}
    </FormControl>
  )
}
