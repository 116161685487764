import React from 'react'

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'

import clsx from 'clsx'

import { useUUID } from 'hooks/useUUID'
import { PreviousPlate } from 'types/api'

import { theme } from '../../../styles/theme'

import styles from './ProgressBar3238.module.scss'

export interface ProgressBarProps extends React.ComponentProps<typeof Box> {
  progress?: number
  gotoPrevPlate: () => void
  prevPlate?: PreviousPlate
  currentStep?: number
}

export interface ProgressTextProps extends React.ComponentProps<typeof Box> {
  progress?: number
}

export interface StartOrBackProps extends React.ComponentProps<typeof Box> {
  showStartButton: boolean
  onClick: () => void
}

const ProgressTextComponent = ({ progress }: ProgressTextProps) => {
  return <Typography className={styles.progressText}>{Math.floor(progress || 0)}%</Typography>
}

const StartOrBackComponent = ({ showStartButton, onClick }: StartOrBackProps) => {
  return (
    <>
      {showStartButton ? (
        <div className={styles.startText}>Start</div>
      ) : (
        <Link component='button' className={styles.backButtonWrapper} onClick={onClick}>
          <ChevronLeftIcon className={styles.backButtonIcon} aria-label='Go to previous question' />
          <Typography className={styles.backButtonText}>Back</Typography>
        </Link>
      )}
    </>
  )
}

export const ProgressBar3238 = ({
  /**
   * @see: https://assuranceiq.atlassian.net/browse/PLEX-3238
   */
  progress: defaultProgress,
  gotoPrevPlate,
  prevPlate,
  currentStep,
  className,
  ...props
}: ProgressBarProps) => {
  const labelID = useUUID()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const showStartButton = currentStep === 0 || !defaultProgress || prevPlate === null
  const progress = showStartButton ? 0 : defaultProgress
  const displayedProgress = Math.min(Math.max(progress || 0, 10), 95)

  return (
    <Box
      className={clsx(styles.barContainer, className, isMobile ? styles.barContainerMobile : '')}
      {...props}
    >
      {isMobile ? (
        <>
          <div className={styles.progressGuidelineWrapper}>
            <StartOrBackComponent showStartButton={showStartButton} onClick={gotoPrevPlate} />
            <ProgressTextComponent progress={progress} />
          </div>
          <LinearProgress
            aria-labelledby={labelID}
            className={styles.progressBar}
            value={displayedProgress}
            variant='determinate'
          />
        </>
      ) : (
        <>
          <StartOrBackComponent showStartButton={showStartButton} onClick={gotoPrevPlate} />
          <LinearProgress
            aria-labelledby={labelID}
            className={styles.progressBar}
            value={displayedProgress}
            variant='determinate'
          />
          <ProgressTextComponent progress={progress} />
        </>
      )}
      <Typography sx={{ display: 'none' }} id={labelID}>
        Your progress bar through the flow is {progress}%
      </Typography>
    </Box>
  )
}
