import React, { FC, ReactNode } from 'react'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'

import clsx from 'clsx'

import { InfoPanel } from 'components/InfoPanel'
import { SanitizedHTML } from 'components/SanitizedHTML'
import { useDeviceType } from 'hooks/useDeviceType'
import { useFeatureFlag } from 'hooks/useFeatureFlag'
import getDomainBranding from 'lib/domain-branding'
import { useStoreActions, useStoreState } from 'lib/store'
import { urlToAPIFormat } from 'utils/url-formatting'

import { BaseFooter, BaseFooterProps } from './BaseFooter'
import { BaseHeader, BaseHeaderProps } from './BaseHeader'
import { ProgressBar } from './ProgressBar'
import { ProgressBar3238 } from './ProgressBar3238'

import styles from './BaseLayout.module.scss'

export interface BaseLayoutProps extends React.ComponentProps<typeof Box> {
  headerProps: BaseHeaderProps
  footerProps: BaseFooterProps
  heroContainer: ReactNode
  children?: ReactNode
  greenBanner?: GreenBannerProps
}

export interface GreenBannerProps {
  title?: string
  subtitle?: string
}

export const GreenBanner: FC<GreenBannerProps> = ({ title, subtitle }) => {
  return (
    <div className={styles.greenBanner}>
      {title && (
        <p>
          <b>{title}</b>
        </p>
      )}
      {subtitle && <SanitizedHTML dirtyHtml={subtitle} />}
    </div>
  )
}

export const MAIN_CONTAINER_ID = 'plate-wrapper'

export const BaseLayout = ({
  headerProps,
  footerProps,
  heroContainer,
  children,
  className,
  greenBanner,
}: BaseLayoutProps) => {
  const showProgressBar3238 = useFeatureFlag('life_test_progress_bar_plex3238') === 'show'
  const path = useStoreState(state => state.current.path)
  const plate = useStoreState(state => state.current.plate)
  const get = useStoreActions(actions => actions.plate.get)
  const progress = useStoreState(state => state.current.path?.progress_percentage)
  const prevPlate = useStoreState(state => state.current.previous_plate)
  const currentStep = useStoreState(state => state.current.path?.current_step)
  const retreaver = useStoreState(state => state.current.retreaver)
  const agentPhone = retreaver?.number
  const agentPhoneFormatted = retreaver?.formatted_number || retreaver?.number
  const { isMobile } = useDeviceType()
  const tty = useStoreState(state => state.current.layout?.options.tty)
  // @ts-ignore we check for property existance when using it
  const hideProgressBar = plate?.component_options?.hide_progress_bar === true
  // @ts-ignore we check for property existence when using it. It's utilizing for subPhoneFooter
  const hasTCPADisclosure = !!plate?.component_options?.next_button?.options?.disclosure
  const ProgressBarComponent = showProgressBar3238 ? ProgressBar3238 : ProgressBar
  const useNewResultPageDesign = plate?.name === 'results' ? true : false

  function goToPreviousPlate() {
    if (prevPlate) {
      get(urlToAPIFormat(prevPlate?.path))
    }
  }

  if (!path) return null

  // @ts-ignore we check for property presence when using it
  const learnMore = plate?.component_options?.learn_more
  const branding = getDomainBranding()
  return (
    <div
      className={clsx(
        styles.root,
        branding.name === 'Assurance' && !useNewResultPageDesign ? styles.branded : '',
        useNewResultPageDesign ? styles.resultPageRoot : '',
        className,
      )}
    >
      <BaseHeader
        {...{
          agentPhone,
          agentPhoneFormatted,
          ...headerProps,
          useNewResultPageDesign,
        }}
      />
      {heroContainer}
      <Container
        component='main'
        id={MAIN_CONTAINER_ID}
        className={useNewResultPageDesign ? styles.resultPageContainer : styles.container}
      >
        {!hideProgressBar && (
          <ProgressBarComponent
            progress={progress}
            gotoPrevPlate={goToPreviousPlate}
            prevPlate={prevPlate}
            currentStep={currentStep}
            className={styles.progressBar}
          />
        )}
        {greenBanner && <GreenBanner title={greenBanner.title} subtitle={greenBanner.subtitle} />}
        <div className={useNewResultPageDesign ? styles.resultPageCard : styles.card}>
          {children}
        </div>
        {learnMore && (
          <InfoPanel
            main={learnMore.sub_text}
            extra={learnMore.popup_text}
            href={learnMore.learn_more_link}
          />
        )}
        {footerProps?.showPhoneSubFooter &&
          !hasTCPADisclosure &&
          agentPhoneFormatted &&
          agentPhone && (
            <div className={styles.phoneSubFooter}>
              <div className={styles.textWithNumber}>
                Or call
                {isMobile ? (
                  <Button variant='text' href={`tel:${agentPhone}`}>
                    {agentPhoneFormatted}
                  </Button>
                ) : (
                  <span>{agentPhoneFormatted}</span>
                )}
                {tty && <>(TTY: {tty})</>}
              </div>
            </div>
          )}
      </Container>

      <BaseFooter
        {...{
          ...footerProps,
          useNewResultPageDesign,
        }}
      />
    </div>
  )
}
