import { useStoreState } from 'lib/store'

import { PlateConfig } from '../types/plate'
import { SimpleFormComponentConfig } from '../types/simple-form-components/input-components'

export const isRadioOrCheckboxComponent = (components: SimpleFormComponentConfig[] | undefined) => {
  if (!components) return false

  return (
    components.find(
      ({ component }) => component === 'Checkboxes' || component === 'RadioButtons',
    ) !== undefined
  )
}

export function useRenderNextButtonAtBottom() {
  const plateGeneric = useStoreState(state => state.current.plate)

  if (plateGeneric?.component !== 'SimpleForm') return
  const plate = plateGeneric as PlateConfig<'SimpleForm'>

  const nextButtonOptions = plate?.component_options?.next_button
  const forceRenderInsidePlate =
    plate?.component_options?.next_button?.options?.force_render_inside_plate || false

  if (forceRenderInsidePlate) return false

  return (
    (!nextButtonOptions || !nextButtonOptions.component) &&
    isRadioOrCheckboxComponent(plate?.component_options?.components)
  )
}
