import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import Button from '@mui/material/Button'

import clsx from 'clsx'

import { useStoreActions, useStoreState } from 'lib/store'
import { urlToAPIFormat } from 'utils/url-formatting'

import styles from './BackButton.module.scss'

export interface BackButtonProps {
  text: string
  className?: string
}

export const BackButton = ({ text, className, ...props }: BackButtonProps) => {
  const isModalPresenting = useStoreState(state => state.current.modalPlatesPresenting)
  const get = useStoreActions(actions => actions.plate.get)
  const prevPlate = useStoreState(state =>
    isModalPresenting ? state.current.modal.previous_plate : state.current.previous_plate,
  )

  const goToPreviousPlate = () => {
    if (prevPlate) {
      get(urlToAPIFormat(prevPlate?.path))
    }
  }

  if (!prevPlate) {
    return null
  }

  return (
    <Button
      startIcon={<ChevronLeftIcon />}
      className={clsx(styles.button, className)}
      onClick={() => goToPreviousPlate()}
      {...props}
    >
      {text}
    </Button>
  )
}
