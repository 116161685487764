import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { action, Action, computed, Computed, thunk, Thunk } from 'easy-peasy'

import { Injections, StoreModel } from 'lib/store'
import { APIResponse, PlateSubmissionData } from 'types/api'
import { PromiseStatus } from 'types/promise'

declare global {
  interface Window {
    urlPrefetchMap?: Record<string, Promise<AxiosResponse<APIResponse>>>
  }
}

export interface PlateModel {
  status: PromiseStatus
  data?: APIResponse
  // HTTP error fetching response from server - for example request couldn't complete for some reason
  error?: AxiosError<APIResponse>
  // error returned from API - API returned 200 but response object contained error description
  errorData?: APIResponse

  isIdle: Computed<PlateModel, boolean>
  isPending: Computed<PlateModel, boolean>
  isRejected: Computed<PlateModel, boolean>
  isResolved: Computed<PlateModel, boolean>

  pending: Action<PlateModel>
  resolved: Action<PlateModel, APIResponse>
  rejected: Action<PlateModel, AxiosError<APIResponse>>

  request: Thunk<PlateModel, AxiosRequestConfig, Injections>
  get: Thunk<PlateModel, string>
  submit: Thunk<PlateModel, PlateSubmissionData | undefined>
}

export const plateModel: PlateModel = {
  status: PromiseStatus.IDLE,
  data: undefined,
  error: undefined,
  errorData: undefined,

  isIdle: computed(state => state.status === PromiseStatus.IDLE),
  isPending: computed(state => state.status === PromiseStatus.PENDING),
  isRejected: computed(state => state.status === PromiseStatus.REJECTED),
  isResolved: computed(state => state.status === PromiseStatus.RESOLVED),

  pending: action(state => {
    state.status = PromiseStatus.PENDING
    state.error = undefined
    state.errorData = undefined
  }),

  resolved: action((state, response) => {
    state.status = PromiseStatus.RESOLVED
    state.data = response
    state.error = undefined
  }),

  rejected: action((state, error) => {
    state.status = PromiseStatus.REJECTED
    state.errorData = error.response?.data
    state.error = error
    console.error(`API request to ${error.config.url} was rejected: `, error.toJSON())
  }),

  request: thunk((actions, requestConfig, { getState, getStoreState, injections }) => {
    if (requestConfig.method !== 'GET') {
      const { data: stateData } = getState()
      const { current: currentModel } = getStoreState() as StoreModel

      let url = currentModel?.plate?.url

      if (currentModel.modalPlatesPresenting) {
        url = currentModel?.modal?.plate?.url
      }

      const authenticity_token = stateData?.session?.authenticity_token

      if (!url) throw new Error('No plate url defined')
      if (!authenticity_token) throw new Error('No authenticity_token defined')

      requestConfig.url = url
      requestConfig.data = { ...requestConfig.data, authenticity_token }
    }
    actions.pending()

    // In order to optimize LCP we are starting XHR request early so it should complete already
    // and we will be just reading results from it here. See public/index.html
    const url = requestConfig.url as string
    const prefetchReq = window?.urlPrefetchMap && window.urlPrefetchMap[url]
    let reqPromise

    if (prefetchReq) {
      // delete cached results so it won't be used again
      // @ts-ignore
      delete window.urlPrefetchMap[url]
      reqPromise = prefetchReq.catch(reason => {
        console.error('Prefetch request failed. Retrying.', reason)
        return injections.platesAPI.request<APIResponse>(requestConfig)
      })
    } else {
      reqPromise = injections.platesAPI.request<APIResponse>(requestConfig)
    }

    return reqPromise
      .then(({ data }) => {
        actions.resolved(data)
      })
      .catch((error: AxiosError<APIResponse>) => {
        if (process.env.NODE_ENV === 'test' && error.message.match(/Could not find mock/))
          throw error

        if (error.response?.data?.context?.plate.component) {
          actions.resolved(error.response.data)
        } else {
          actions.rejected(error)
        }
      })
  }),

  get: thunk((actions, url) => {
    return actions.request({ method: 'GET', url })
  }),

  submit: thunk((actions, data) => actions.request({ method: 'POST', data })),
}
