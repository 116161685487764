import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import Box from '@mui/material/Box'
import FormHelperText from '@mui/material/FormHelperText'
import TextField from '@mui/material/TextField'

import clsx from 'clsx'
import { useFormContext } from 'react-hook-form'

import { useFieldRules } from 'hooks/useFieldRules'
import { useInputState } from 'hooks/useInputState'
import { useUUID } from 'hooks/useUUID'
import {
  InputConfig,
  SimpleFormComponentProps,
} from 'types/simple-form-components/input-components'
import { convertToIMaskOptions, getMaskMaxValue } from 'utils/transform-imask'

import { ReactComponent as SignWarning } from 'images/icons/sign-warning.svg'

import { useFocusSwitcher } from '../FocusSwitcher'
import { useController, UseControllerProps } from '../FormProvider'
import { InlineLabeledFormControl } from '../InlineLabeledFormControl'
import { useValidationContext } from '../Validation'

import styles from './Input.module.scss'

export interface InputProps extends SimpleFormComponentProps<InputConfig> {
  showErrorMessage?: boolean
}

export type InputFieldValues = Record<string, string>

function padValueWithZeros(value: string, minLength: number) {
  if (!value || value.length >= minLength) return value

  return '0'.repeat(minLength - value.length) + value
}

export const InputBase = ({
  field: name,
  label,
  suffix,
  attrs: { 'aria-labelledby': ariaLabelledBy, ...attrs } = {},
  inputmode,
  mask_options,
  onChange,
  rules: defaultRules,
  validateField,
  validation,
  value,
  input_attrs,
  showErrorMessage = true,
  InputProps,
  mui_popup_label = false,
  ...textFieldProps
}: InputProps) => {
  const rules = useFieldRules({ defaultRules, validateField, field: name, validation })
  const { control } = useFormContext()
  const {
    field: {
      ref: controllerRef,
      onChange: controllerOnChange,
      value: controllerValue,
      onBlur: controllerOnBlur,
      ...field
    },
    fieldState,
  } = useController({
    enableForceValidation: true,
    name,
    control,
    defaultValue: value ?? '', // Can not be undefined
    rules,
  } as UseControllerProps<InputFieldValues>)
  const ref = useRef<HTMLInputElement | null>(null)

  const [mask, setMask] = useState<IMask.InputMask<IMask.AnyMaskedOptions> | undefined>()
  const helperTextID = useUUID()

  const max = useMemo(() => getMaskMaxValue(mask_options || {}), [mask_options])
  const { onKeyDown } = useFocusSwitcher({ name, fieldState, ref, max, isSelectable: true })

  const iMaskOptions = useMemo(() => {
    return mask_options && convertToIMaskOptions(mask_options)
  }, [mask_options])

  const initMask = useCallback(
    (element: HTMLInputElement | null) => {
      if (!element || mask || !iMaskOptions) return

      // Load IMask dynamically because it is pretty big and will inflate bundle size
      import('imask').then(IMaskModule => {
        if (!element) return

        const IMask = IMaskModule.default
        const newMask = IMask(element, iMaskOptions)
        newMask.value = value || ''

        newMask.on('accept', () => {
          controllerOnChange(newMask.unmaskedValue)
        })

        setMask(newMask)
        if (process.env.NODE_ENV === 'test') {
          // @ts-ignore
          element.hasIMask = true
        }
      })
    },
    [controllerOnChange, mask, iMaskOptions, value],
  )

  const onBlur = () => {
    controllerOnBlur()

    const { requiredNumberLength } = mask_options || {}
    if (requiredNumberLength) {
      const paddedValue = padValueWithZeros(controllerValue, requiredNumberLength)

      if (paddedValue !== controllerValue) {
        controllerOnChange(paddedValue)
      }
    }
  }

  useEffect(() => {
    if (!mask || !controllerValue) return

    // Deal with the Number type
    mask.value = controllerValue.toString()
  }, [controllerValue, mask])

  useEffect(() => {
    if (!mask) return

    return () => mask.destroy()
  }, [mask])

  return (
    <div className={suffix ? styles.inputRow : undefined}>
      <TextField
        {...textFieldProps}
        {...field}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        label={label}
        className={clsx(
          styles.standard,
          fieldState?.isValid ? styles.valid : '',
          field.name === 'birth_month' || field.name === 'birth_day'
            ? styles.width_64
            : field.name === 'birth_year'
            ? styles.width_84
            : '',
        )}
        value={iMaskOptions ? undefined : controllerValue}
        onChange={iMaskOptions ? undefined : controllerOnChange}
        inputProps={{
          inputMode: inputmode,

          'aria-labelledby': label ? undefined : ariaLabelledBy,

          'aria-describedby': fieldState.error ? undefined : helperTextID,
          'aria-errormessage': fieldState.error ? helperTextID : undefined,

          'aria-required': !!rules?.required,

          ...attrs,

          ...input_attrs,
        }}
        inputRef={element => {
          ref.current = element

          controllerRef(element)
          initMask(element)
        }}
        error={!!fieldState.error}
        InputLabelProps={
          iMaskOptions && (!mui_popup_label || controllerValue.length > 0) ? { shrink: true } : {}
        }
        FormHelperTextProps={{
          id: helperTextID,
          role: fieldState.error && 'alert',
        }}
        helperText={showErrorMessage && (textFieldProps.error || fieldState.error?.message)}
        variant='outlined'
        fullWidth
        InputProps={{
          endAdornment: fieldState.error && <SignWarning className={styles.errorIcon} />,
          ...InputProps,
        }}
      />
      <div className={styles.suffixText}>{suffix}</div>
    </div>
  )
}

export const Input = ({
  attrs = {},
  field,
  label,
  label_position = 'top',
  ...otherProps
}: InputProps) => {
  const { 'aria-labelledby': ariaLabelledBy, ...otherAttrs } = attrs
  const labelID = useUUID()
  const errorTextID = useUUID()
  const { focused, hovered, inputEventHandlers } = useInputState()

  const { field: { isValid } = {} } = useValidationContext(field)

  const {
    formState: { errors },
  } = useFormContext()
  const fieldError = errors[field]

  let autoComplete

  switch (field) {
    case 'fname':
      autoComplete = 'given-name'
      break
    case 'lname':
      autoComplete = 'family-name'
      break
  }

  if (label_position === 'left') {
    return (
      <Box className={styles.root}>
        <InlineLabeledFormControl
          focused={focused}
          hovered={hovered}
          label={label}
          labelID={labelID}
          error={!!fieldError}
          isValid={isValid}
        >
          <Box className={styles.inlineLabeledInput}>
            <InputBase
              field={field}
              attrs={{
                autoComplete,
                ...otherAttrs,
                ...inputEventHandlers,
                'aria-labelledby': labelID,
                'aria-describedby': fieldError ? undefined : errorTextID,
                'aria-errormessage': fieldError ? errorTextID : undefined,
              }}
              InputProps={{ sx: { borderRadius: 0, border: 'none' } }}
              showErrorMessage={false}
              {...otherProps}
            />
          </Box>
        </InlineLabeledFormControl>
        {fieldError && (
          <FormHelperText className={styles.error} id={errorTextID} role='alert'>
            {fieldError?.message}
          </FormHelperText>
        )}
      </Box>
    )
  }

  return (
    <Box className={styles.root}>
      <InputBase
        field={field}
        attrs={{
          autoComplete,
          ...attrs,
        }}
        label={label}
        {...otherProps}
      />
    </Box>
  )
}
