import AppBar from '@mui/material/AppBar'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'

import clsx from 'clsx'

import { useDeviceType } from 'hooks/useDeviceType'
import getDomainBranding from 'lib/domain-branding'

import { ReactComponent as PhoneRing } from 'images/icons/phone-ring.svg'

import styles from './BaseHeader.module.scss'

export interface BaseHeaderProps {
  adDisclosureLink?: string
  showAdDisclosureLink?: boolean
  agentCallHours?: Array<string>
  agentPhone?: string
  agentPhoneFormatted?: string
  tty?: string
  useNewResultPageDesign?: boolean
}

export const BaseHeader = ({
  adDisclosureLink,
  showAdDisclosureLink = false,
  agentCallHours,
  agentPhone,
  agentPhoneFormatted,
  tty,
  useNewResultPageDesign = false,
}: BaseHeaderProps) => {
  const branding = getDomainBranding()
  const { isMobile } = useDeviceType()

  return (
    <AppBar
      position='relative'
      color='default'
      className={clsx(useNewResultPageDesign ? styles.resultPageRoot : '', styles.root)}
    >
      <Container className={styles.top}>
        <img
          className={styles.brandLogo}
          src={branding.logo_static}
          alt={branding.domain_short + ' logo'}
        />
        <div className={styles.headerRightBlock}>
          {isMobile && agentPhone && (
            <Button
              className={styles.callButton}
              variant='contained'
              startIcon={<PhoneRing className={styles.callButtonIcon} />}
              href={`tel:${agentPhone}`}
            >
              Call
            </Button>
          )}
          {!isMobile && agentPhone && (
            <>
              <Typography variant='inherit' className={styles.headerBannerText}>
                Speak with a Licensed Insurance Agent
              </Typography>
              <div className={styles.callLink}>
                <span>{agentPhoneFormatted}</span> {tty && <>(TTY: {tty})</>}
              </div>
              {agentCallHours && (
                <Typography variant='inherit' className={styles.headerBannerText}>
                  {agentCallHours.join('; ')}
                </Typography>
              )}
            </>
          )}
          {showAdDisclosureLink && adDisclosureLink && (
            <Link
              className={styles.disclosure}
              href={adDisclosureLink}
              title='Advertising Disclosure'
              target='_blank'
            >
              Advertising Disclosure
            </Link>
          )}
        </div>
      </Container>
    </AppBar>
  )
}
