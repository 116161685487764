import ALL_DOMAINS_BRANDING_JSON from '../domain_branding_generated.json'

// MUST keep in sync with generate-domain-data.js#DOMAIN_BRANDING_FIELDS
export interface DomainBrandingData {
  name: string
  favicon: string
  logo_static?: string
  logo_mobile?: string
  domain_short: string
  domain_long: string
  gtm?: string // The GTM container ID
  agent_phone?: string
}

const ALL_DOMAINS_BRANDING = ALL_DOMAINS_BRANDING_JSON as Record<string, DomainBrandingData>
let domainBranding: DomainBrandingData

export default function getDomainBranding() {
  if (domainBranding) {
    return domainBranding
  }
  return calculateDomainBranding()
}

// As a last resort - if config is not loaded correctly in build time (should never happen (famous words))
// It may get stale over time and we will need to remember to keep updating it but it is better than losing a shopper
const DEFAULT_FALLBACK: DomainBrandingData = {
  name: 'Assurance',
  favicon: 'https://assets.assurance.com/img/logos/assurance.png',
  domain_short: 'assurance',
  domain_long: 'assurance.com',
  logo_static: 'https://assets.assurance.com/img/logos/assurance.png',
  gtm: 'GTM-T6DSLL',
  agent_phone: '8446661569',
}

export function calculateDomainBranding(): DomainBrandingData {
  if (!ALL_DOMAINS_BRANDING) {
    console.error(
      'Critical error: ALL_DOMAINS_BRANDING is absent in runtime. Possible build-time issue. Falling back to cached default',
    )

    return DEFAULT_FALLBACK
  }
  let currentDomain = Object.keys(ALL_DOMAINS_BRANDING).find(
    domainKey => window.location.hostname.indexOf(domainKey) !== -1,
  )

  if (
    ['localhost', '127.0.0.1', 'plates.assurance.dev', 'new-plates.assurance.dev'].includes(
      window.location.hostname,
    )
  ) {
    // special treatment for local development. Return random domain config
    currentDomain = 'default'
    console.info(`Using ${currentDomain} domain_branding for localhost/integration testing`)
  }

  if (currentDomain) {
    return (domainBranding = ALL_DOMAINS_BRANDING[currentDomain])
  }
  console.error('Could not find domain_branding for current hostname: ', window.location.hostname)

  // fallback
  return ALL_DOMAINS_BRANDING['default']
}
