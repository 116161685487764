import { ReactNode, useEffect, useRef } from 'react'

import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import MUILink from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import clsx from 'clsx'
import capitalize from 'lodash/capitalize'

import { useDeviceType } from 'hooks/useDeviceType'
import getDomainBranding from 'lib/domain-branding'
import { useStoreState } from 'lib/store'
import { logAction } from 'utils/datadog'

import { ReactComponent as PhoneRing } from 'images/icons/phone-ring.svg'

import styles from './BaseFooter.module.scss'

interface LinkInterface {
  title: string
  url: string
}

const LINKS: Array<LinkInterface> = [
  { title: 'Terms of Service', url: '/terms-and-conditions' },
  { title: 'Privacy Center', url: '/privacy-center' },
  {
    title: 'Do Not Sell nor Share my Personal Information',
    url: '/california-opt-out',
  },
  { title: 'Contact Us', url: '/contact-us' },
  { title: 'Communication Opt Out', url: '/comm-opt-out' },
  { title: 'Accessibility', url: 'https://www.prudential.com/links/about/accessibility-form' },
]

const Copyright = () => {
  const currentYear = new Date().getFullYear()

  return (
    <Typography variant='inherit' mb={1}>
      ©{currentYear} {capitalize(getDomainBranding().domain_long)} {currentYear}. All rights
      reserved.
    </Typography>
  )
}

export const Link = ({ url, title }: LinkInterface) => (
  <MUILink target='_blank' rel='noopener' href={url} color='inherit'>
    {title}
  </MUILink>
)

const Links = () => {
  return (
    <div className={styles.links}>
      <Stack direction='row' component='ul' spacing={1}>
        {LINKS.map(({ title, url }) => (
          <li className={styles.link} key={url + title}>
            <Link url={url} title={title} />
          </li>
        ))}
      </Stack>
    </div>
  )
}

interface CallAgentInterface {
  callHours: Array<string>
  tty?: string
}

const CallAgent = ({ callHours, tty }: CallAgentInterface) => {
  const retreaver = useStoreState(state => state.current.retreaver)
  const agentPhone = retreaver?.number
  const agentPhoneFormatted = `${retreaver?.formatted_number || retreaver?.number}`
  const { isMobile } = useDeviceType()

  if (!agentPhone) return null
  return (
    <div className={styles.callAgent}>
      <Typography variant='inherit' className={styles.title}>
        Speak with a licensed insurance agent:
      </Typography>
      <div className={styles.number}>
        <Button
          variant='text'
          startIcon={<PhoneRing />}
          href={isMobile ? `tel:${agentPhone}` : '#'}
        >
          {agentPhoneFormatted}
        </Button>
        {tty && <>TTY: {tty}</>}
      </div>
      <div className={styles.callHours}>
        <ul>
          {callHours.map(option => (
            <li key={option}>{option}</li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export interface BaseFooterProps {
  mainText: ReactNode
  widgets?: ReactNode
  redOakId?: string
  agentCallHours: Array<string>
  showPhoneSubFooter?: boolean
  useNewResultPageDesign?: boolean
  tty?: string
}

export const BaseFooter = ({
  mainText,
  redOakId,
  widgets,
  agentCallHours,
  tty,
  useNewResultPageDesign,
}: BaseFooterProps) => {
  const preRef = useRef<HTMLDivElement>(null)
  const postRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (typeof IntersectionObserver === 'undefined' || !preRef.current || !postRef.current) {
      return
    }
    let isFirstRun = true
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.intersectionRatio > 0.5 && isFirstRun) {
            // element was visible on page load - do not send event
            observer.unobserve(entry.target)
            return
          }

          if (entry.isIntersecting) {
            logAction('footer_saw_' + entry.target.id)
            observer.unobserve(entry.target)
          }
        })
        isFirstRun = false
      },
      {
        threshold: 0.5,
      },
    )
    observer.observe(preRef.current)
    observer.observe(postRef.current)

    return () => {
      observer.disconnect()
    }
  }, [preRef])

  return (
    <footer className={clsx(styles.root, useNewResultPageDesign ? styles.resultPageRoot : '')}>
      <Container>
        {widgets && <div className={styles.widgets}>{widgets}</div>}
        <CallAgent callHours={agentCallHours} tty={tty} />
        <Links />
        <Copyright />
        <div className={styles.mainText}>
          <div ref={preRef} id='pre-disclaimer' />
          {mainText}
          <div ref={postRef} id='post-disclaimer' />
        </div>
        {redOakId && (
          <Typography mb={0} variant='inherit' align='center'>
            {redOakId}
          </Typography>
        )}
      </Container>
    </footer>
  )
}
