import {
  SimpleFormComponentProps,
  StateSelectConfig,
} from 'types/simple-form-components/input-components'

import { Select } from '../Select'
import { states } from './states'

export type StateSelectProps = SimpleFormComponentProps<StateSelectConfig>

export const StateSelect = (props: StateSelectProps) => {
  return <Select {...props} dropdown_options={states} />
}
