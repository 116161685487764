import { lazy } from 'react'

import { LazyPlateComponent, PlateComponentName, PlateComponents } from 'types/plate'

type LazyPlateComponentMap = {
  [N in PlateComponentName]: LazyPlateComponent<PlateComponents[N]>
}

export const lazyPlates: LazyPlateComponentMap = {
  Buttons: lazy(() => import('./Buttons')),
  ImageButtons: lazy(() => import('./ImageButtons')),
  Results: lazy(() => import('./Results')),
  Redirect: lazy(() => import('./Redirect')),
  SimpleForm: lazy(() => import('./SimpleForm')),
  Loader: lazy(() => import('./Loader')),
  CallInfo: lazy(() => import('./CallInfo')),
}
