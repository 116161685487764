import { CallInfoMetadata, ExtendedOffering, Metadata, OfferingType } from 'types/plate'
import { DEPLOY_ENV } from 'utils/environment-variables'

export function processOfferData(
  offers: ExtendedOffering | ExtendedOffering[],
  metadata: Metadata,
  eventType: string,
  eventName: string,
  precedingCards?: number,
) {
  const items = Array.isArray(offers)
    ? offers.map(processSingleOffer)
    : [processSingleOffer(offers)]

  const timestamp = Date.now()

  return {
    items,
    ...metadata,
    event_type: eventType,
    eventName: eventName,
    ...(typeof precedingCards === 'number' ? { preceding_cards: precedingCards } : {}),
    timestamp: timestamp,
    url: window.location.href,
  }
}

const processSingleOffer = (offer: ExtendedOffering, index = 0) => {
  let itemData = {}
  let offerId

  if (offer.product_type === OfferingType.AD) {
    itemData = {
      ad_vendor_name: offer.vendor_name,
      ad_vendor_id: offer.vendor_id,
      ad_company_name: offer.company_name,
      ad_cpc: offer.cpc,
      ad_term_length: offer.term_length,
      ad_term_display: offer.termValue,
      ad_coverage_min: offer.coverage?.min,
      ad_coverage_max: offer.coverage?.max,
      ad_coverage_display: offer.coverageValue,
    }
    offerId = offer.ad_id?.toString()
  } else if (offer.product_type === OfferingType.QUOTE) {
    itemData = {
      quote_product_id: offer.product_id,
      quote_carrier: offer.carrier,
      quote_product_name: offer.product_name,
      quote_term_length: offer.product_data?.term_length,
      quote_term_display: offer.termValue,
      quote_coverage_amount: offer.product_data?.coverage_amount,
      quote_coverage_display: offer.coverageValue,
      quote_expected_monthly_premium: offer.estimated_monthly_cost,
      quote_emp_display: offer.estimation,
    }
    offerId = offer.product_data?.product_arn
  }

  return {
    id: offerId,
    product_type: offer.product_type.toLowerCase(),
    item_data: itemData,
    position: index,
    layout: 'list',
  }
}

export const callInfoCSI = ({ lead_id, direct_lead_id }: CallInfoMetadata) => {
  return {
    element_id: direct_lead_id,
    experience: 'dental guided rec',
    timestamp: new Date().getTime().toString(),
    metadata: {
      lead_id: lead_id ? lead_id.toString() : null,
      event_type: 'click_to_call_button',
      url: window.location.href,
      environment: DEPLOY_ENV,
    },
  }
}
