import { useEffect, useState } from 'react'

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import Button, { ButtonProps } from '@mui/material/Button'

import clsx from 'clsx'

import { Progress } from 'components/Progress/Progress'
import { useStoreActions, useStoreState } from 'lib/store'
import { SkipButtonConfig } from 'types/plate'

import styles from './SkipButton.module.scss'

export interface SkipButtonProps extends ButtonProps {
  questionID: string
  tabIndex?: number
  field?: string
  config: SkipButtonConfig
}

export const SkipButton = ({ questionID, tabIndex, field, config, ...rest }: SkipButtonProps) => {
  const isPending = useStoreState(state => state.plate.isPending)
  const submit = useStoreActions(actions => actions.plate.submit)

  const [isSkipPending, setIsSkipPending] = useState<boolean>(false)

  const skipField = config.field ?? `skipped_${field}`
  const { text = 'Skip' } = config

  const onSkipSubmit = (value: string, skipField: string) => {
    if (isPending) return

    setIsSkipPending(true)
    submit({ [skipField]: value })
  }

  useEffect(() => {
    if (!isPending) setIsSkipPending(false)
  }, [isPending])

  const buttonProps: ButtonProps = {
    'aria-describedby': questionID,
    'aria-disabled': isSkipPending,
    tabIndex: tabIndex,
    className: clsx(styles.root, rest?.className),
    endIcon: <ArrowForwardIosIcon className={styles.icon} />,
    onClick: () => onSkipSubmit(config.value, skipField),
    ...rest,
  }

  return <Button {...buttonProps}>{isSkipPending ? <Progress /> : text}</Button>
}
