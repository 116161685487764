import { CSSProperties } from 'react'

import MUISvgIcon, { SvgIconProps as MUISvgIconProps } from '@mui/material/SvgIcon'

export interface SvgIconProps extends MUISvgIconProps {
  inline?: boolean
}

/**
 * Wrapper component for adding custom styles
 */
export const SvgIcon = ({ inline = false, ...props }: SvgIconProps) => {
  const sx: CSSProperties = inline
    ? {
        position: 'relative',
        top: '0.1em',
        ...props.style,
      }
    : { ...props.style }

  return (
    <MUISvgIcon
      sx={{
        fontSize: 'inherit',
        ...sx,
      }}
      {...props}
    />
  )
}
