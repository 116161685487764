import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react'

import { ControllerFieldState, useFormContext } from 'react-hook-form'

import { onStorybookDocsPage } from 'stories/story-utils'

interface FieldProps {
  name: string
  fieldState: ControllerFieldState
  ref: React.MutableRefObject<HTMLElement | null>

  /**
   * If true, the content of the field will be selected.
   *
   * @default false
   */
  isSelectable?: boolean
  max?: string | number | boolean
}

interface SwitcherContextProps {
  fields: Array<FieldProps>
  setFields: React.Dispatch<React.SetStateAction<SwitcherContextProps['fields']>>
}

const SwitcherContext = createContext<SwitcherContextProps | undefined>(undefined)
SwitcherContext.displayName = 'FocusSwitcherContext'

/**
 * FocusSwitcher is a component that allows you to switch focus between inputs
 * when a certain condition is met.
 * Order of inputs is determined by the order of useFocusSwitcher initialization.
 */
export const useFocusSwitcher = ({
  name,
  fieldState,
  isSelectable = false,
  max,
  ref,
}: FieldProps) => {
  const [userInteracted, setUserInteracted] = useState<boolean>(false)
  const [switchToPrevField, setSwitchToPrevField] = useState<boolean>(false)
  const switcherContext = useContext(SwitcherContext)

  if (!switcherContext) {
    throw new Error('useFocusSwitcher must be used within a FocusSwitcher')
  }

  const { fields, setFields } = switcherContext

  const { setFocus, watch } = useFormContext()
  const fieldValue = watch(name)

  useEffect(() => {
    setFields(fields => [...fields, { name, fieldState, isSelectable, max, ref }])

    return () => {
      // clean up the field array when this component is unmounted
      setFields(fields => fields.filter(field => field.name !== name))
    }

    // prevent infinite loop by excluding fieldState and ref
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, max, setFields])

  useEffect(() => {
    if (!fieldValue || !max || !userInteracted) return

    const index = fields.findIndex(field => field.name === name)
    const nextFieldName = index > fields.length - 1 ? null : fields[index + 1]?.name

    if (
      nextFieldName &&
      (fieldValue.length >= max.toString().length || Number(fieldValue) * 10 > Number(max))
    ) {
      setFocus(nextFieldName)
    }

    // exclude “userInteracted” due to a race condition with the “watch” hook
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields, name, fieldValue, max, setFocus])

  useEffect(() => {
    if (!switchToPrevField) return

    const index = fields.findIndex(field => field.name === name)
    const prevFieldName = index > 0 ? fields[index - 1]?.name : null

    if (prevFieldName) {
      setFocus(prevFieldName)
    }
    setSwitchToPrevField(false)
  }, [name, fields, setFocus, switchToPrevField])

  const onKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    if (!userInteracted) {
      setUserInteracted(true)
    }

    const element = event.target as HTMLInputElement

    if (event.key === 'Backspace' && element.value === '') {
      setSwitchToPrevField(true)
    }
  }

  return { fields, onKeyDown }
}

export interface FocusSwitcherProps extends PropsWithChildren<unknown> {
  defaultState?: Array<FieldProps>
}

export const FocusSwitcher = ({ defaultState = [], ...props }: FocusSwitcherProps) => {
  const [fields, setFields] = useState<Array<FieldProps>>(defaultState)
  const [focused, setFocused] = useState<boolean>(false)
  const { watch } = useFormContext()

  const {
    formState: { isSubmitting, errors },
    setFocus,
  } = useFormContext()

  useEffect(() => {
    if (isSubmitting || Object.keys(errors).length === 0) return

    const firstErrorField = fields.find(field => errors[field.name])

    if (!firstErrorField?.ref.current?.scrollIntoView) return

    // force scroll to the first error field
    firstErrorField.ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }, [errors, fields, isSubmitting])

  useEffect(() => {
    // HACK: Prevent multi-focus race conditions (storybook docs render multiple SimpleForms)
    if (onStorybookDocsPage()) return

    if (focused || fields.length === 0) return

    setFocus(fields[0].name, {
      // Select content if a control supports it
      shouldSelect: fields[0].isSelectable && !!watch(fields[0].name),
    })
    setFocused(true)
  }, [focused, fields, setFocus, watch])

  return <SwitcherContext.Provider value={{ fields, setFields }} {...props} />
}
