import React from 'react'

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'

import clsx from 'clsx'

import { useUUID } from 'hooks/useUUID'
import { PreviousPlate } from 'types/api'

import styles from './ProgressBar.module.scss'

export interface ProgressBarProps extends React.ComponentProps<typeof Box> {
  progress?: number
  gotoPrevPlate: () => void
  prevPlate?: PreviousPlate
  currentStep?: number
}

export const ProgressBar = ({
  progress,
  gotoPrevPlate,
  prevPlate,
  currentStep,
  className,
  ...props
}: ProgressBarProps) => {
  const labelID = useUUID()

  const showBackButton =
    !!progress && progress > 0 && currentStep !== 0 && prevPlate && !!prevPlate?.path

  const displayedProgress = Math.min(Math.max(progress || 0, 10), 95)

  return (
    <Box className={clsx(styles.barContainer, className)} {...props}>
      {(currentStep === 0 || !progress) && <div className={styles.startText}>Start</div>}
      {showBackButton && (
        <Link component='button' className={styles.backButtonWrapper} onClick={gotoPrevPlate}>
          <ChevronLeftIcon className={styles.backButtonIcon} aria-label='Go to previous question' />
          <Typography className={styles.backButtonText}>Back</Typography>
        </Link>
      )}
      <LinearProgress
        aria-labelledby={labelID}
        className={styles.progressBar}
        value={displayedProgress}
        variant='determinate'
      />
      <Typography sx={{ display: 'none' }} id={labelID}>
        Your progress bar through the flow is {progress}%
      </Typography>
    </Box>
  )
}
