import { SvgIcon, SvgIconProps } from 'icons'

export const UmbrellaIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox='0 0 16 16' {...props}>
      <g transform='matrix(1,0,0,1,0,0)'>
        <path d='M7.54752 11.2667C7.52264 11.2591 7.49635 11.2576 7.47075 11.2621C7.44516 11.2666 7.42098 11.2771 7.40017 11.2926C7.37936 11.3082 7.36249 11.3284 7.35093 11.3517C7.33937 11.375 7.33343 11.4007 7.3336 11.4267V14.1667C7.3336 14.6529 7.52668 15.1192 7.87036 15.463C8.21404 15.8068 8.68016 16 9.1662 16C9.65223 16 10.1184 15.8068 10.462 15.463C10.8057 15.1192 10.9988 14.6529 10.9988 14.1667C10.9988 13.9899 10.9286 13.8203 10.8036 13.6953C10.6786 13.5702 10.5091 13.5 10.3324 13.5C10.1557 13.5 9.98616 13.5702 9.86118 13.6953C9.73621 13.8203 9.666 13.9899 9.666 14.1667C9.666 14.2993 9.61334 14.4265 9.51961 14.5202C9.42588 14.614 9.29875 14.6667 9.1662 14.6667C9.03364 14.6667 8.90652 14.614 8.81279 14.5202C8.71906 14.4265 8.6664 14.2993 8.6664 14.1667V11.4267C8.66647 11.4007 8.66045 11.3751 8.64885 11.3519C8.63724 11.3287 8.62036 11.3086 8.59956 11.2931C8.57876 11.2776 8.55463 11.2672 8.52908 11.2627C8.50354 11.2583 8.47731 11.2598 8.45249 11.2673C8.15724 11.3553 7.84276 11.3547 7.54752 11.2667Z' />
        <path d='M8 0C7.82326 0 7.65376 0.0702379 7.52879 0.195262C7.40381 0.320286 7.3336 0.489856 7.3336 0.666667V0.882C7.33363 0.923274 7.31828 0.963078 7.29056 0.993647C7.26284 1.02421 7.22473 1.04336 7.18366 1.04733C5.16729 1.27868 3.31098 2.2582 1.98151 3.79236C0.652045 5.32652 -0.053881 7.30373 0.00321126 9.33333V9.66667C0.00321126 9.84348 0.073421 10.013 0.198395 10.1381C0.323369 10.2631 0.49287 10.3333 0.66961 10.3333C0.84635 10.3333 1.01585 10.2631 1.14083 10.1381C1.2658 10.013 1.33601 9.84348 1.33601 9.66667C1.33601 9.31133 1.80249 9 2.33561 9C2.86873 9 3.33521 9.31133 3.33521 9.66667C3.33521 9.84348 3.40542 10.013 3.53039 10.1381C3.65536 10.2631 3.82487 10.3333 4.00161 10.3333C4.17835 10.3333 4.34785 10.2631 4.47282 10.1381C4.5978 10.013 4.668 9.84348 4.668 9.66667C4.668 9.39467 5.18713 9 6.0008 9C6.81448 9 7.3336 9.39467 7.3336 9.66667C7.3336 9.84348 7.40381 10.013 7.52879 10.1381C7.65376 10.2631 7.82326 10.3333 8 10.3333C8.17674 10.3333 8.34624 10.2631 8.47122 10.1381C8.59619 10.013 8.6664 9.84348 8.6664 9.66667C8.6664 9.39467 9.18552 9 9.9992 9C10.8129 9 11.332 9.39467 11.332 9.66667C11.332 9.84348 11.4022 10.013 11.5272 10.1381C11.6522 10.2631 11.8217 10.3333 11.9984 10.3333C12.1751 10.3333 12.3446 10.2631 12.4696 10.1381C12.5946 10.013 12.6648 9.84348 12.6648 9.66667C12.6648 9.31133 13.1313 9 13.6644 9C14.1975 9 14.664 9.31133 14.664 9.66667C14.664 9.84348 14.7342 10.013 14.8592 10.1381C14.9841 10.2631 15.1537 10.3333 15.3304 10.3333C15.5071 10.3333 15.6766 10.2631 15.8016 10.1381C15.9266 10.013 15.9968 9.84348 15.9968 9.66667V9.33333C16.0539 7.30373 15.348 5.32652 14.0185 3.79236C12.689 2.2582 10.8327 1.27868 8.81634 1.04733C8.77527 1.04336 8.73716 1.02421 8.70944 0.993647C8.68172 0.963078 8.66637 0.923274 8.6664 0.882V0.666667C8.6664 0.489856 8.59619 0.320286 8.47122 0.195262C8.34624 0.0702379 8.17674 0 8 0Z' />
      </g>
    </SvgIcon>
  )
}
