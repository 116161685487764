import axios from 'axios'

import { API_ORIGIN } from 'utils/constants'

export const platesAPI = axios.create({
  baseURL: API_ORIGIN,
  withCredentials: true,
  headers: {
    Accept: 'application/json',
  },
})
