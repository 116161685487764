import { useState } from 'react'

type RandomUUIDOf<T> = T extends { randomUUID: () => string } ? T['randomUUID'] : () => string

declare global {
  interface Window {
    crypto?: Crypto & {
      randomUUID?: RandomUUIDOf<Crypto>
    }
  }
}

export const generateUUID = () => generateUUIDs(1)[0]

export const generateUUIDs = (count = 1) => {
  return Array.from(
    { length: count },
    () => window?.crypto?.randomUUID?.() || Math.random().toString(36).slice(2),
  )
}

/**
 * React hook that generates a list of UUIDs
 */
export const useUUIDs = (count: number) => {
  // Utilize useState instead of useMemo because React
  // makes no guarantee it won’t recalculate useMemo values
  // SEE: https://reactjs.org/docs/hooks-reference.html#usememo:~:text=You%20may%20rely,to%20optimize%20performance.
  const [ids] = useState(generateUUIDs(count))

  return ids
}
