import { ComponentProps } from 'react'

import {
  FieldValues,
  FormProvider as RHFProvider,
  useController as useRHFController,
  UseControllerProps as UseRHFControllerProps,
  UseControllerReturn as UseRHFControllerReturn,
} from 'react-hook-form'

import { useValidation, Validation, ValidationProps } from '../Validation'

interface FormProviderProps extends ComponentProps<typeof RHFProvider> {
  validationProps?: ValidationProps
}

export type UseControllerProps<T extends FieldValues> = UseRHFControllerProps<T> & {
  /**
   * If true, the validation will be triggered on blur
   * @default false
   */
  enableForceValidation?: boolean
}

export type UseControllerReturn<T extends FieldValues> = UseRHFControllerReturn<T> & {
  fieldState: UseRHFControllerReturn<T>['fieldState'] & {
    /**
     * If true, the field value is not empty and is valid
     */
    isValid?: boolean
  }
}

/**
 * This is a wrapper around react-hook-form's useController hook
 * that brings additional functionality
 */
export const useController = ({
  enableForceValidation = false,
  ...props
}: UseControllerProps<FieldValues>): UseControllerReturn<FieldValues> => {
  const RHFController = useRHFController(props)

  const { field: validationState, onBlur: validationOnBlur } = useValidation({
    enableForceValidation,
    name: props.name,
    defaultValue: RHFController.field.value,
  })

  const onBlur = () => {
    RHFController.field.onBlur()
    validationOnBlur()
  }

  return {
    ...RHFController,
    field: { ...RHFController.field, onBlur },
    fieldState: { ...RHFController.fieldState, ...validationState },
  }
}

/**
 * This is a wrapper around react-hook-form's FormProvider that brings additional functionality
 */
export const FormProvider = ({ children, validationProps, ...props }: FormProviderProps) => {
  return (
    <RHFProvider {...props}>
      <Validation {...validationProps}>{children}</Validation>
    </RHFProvider>
  )
}
