import { useMemo } from 'react'

import { UseControllerProps, Validate } from 'react-hook-form'

import { ValidationProps } from 'types/simple-form-components/input-components'

interface UseFieldRulesProps {
  /** The react-hook-form rules */
  defaultRules?: UseControllerProps['rules']

  /** The name of the field */
  field?: string

  /** Currying function for closuring “field” into the validate function */
  validateField?: (field: string) => Validate<unknown, unknown>

  /** Validation options that can go from a client response */
  validation?: ValidationProps
}

function convertToRules({ pattern, ...otherProps }: ValidationProps): UseControllerProps['rules'] {
  return {
    ...otherProps,
    pattern: pattern && { value: new RegExp(pattern.value), message: pattern.message },
  }
}

/**
 * Hook to merge a validate function with other rules.
 * Here we are using the currying pattern to closure the field name to the validate function.
 *
 * @returns The merged react-hook-form rules
 */
export const useFieldRules = ({
  defaultRules,
  validateField,
  field,
  validation,
}: UseFieldRulesProps): UseControllerProps['rules'] => {
  return useMemo(() => {
    const configRules = validation && convertToRules(validation)
    const validate = validateField && field ? validateField(field) : undefined

    return { ...defaultRules, ...configRules, validate }
  }, [defaultRules, field, validateField, validation])
}
