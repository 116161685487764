import React, { forwardRef } from 'react'

import Button, { ButtonProps } from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import type { DialogProps } from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'

import { IconClose } from '@assuranceiq/react-icons/bold/Close'

import clsx from 'clsx'

import { theme } from 'styles/theme'

import styles from './ModalDialog.module.scss'

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction='up' ref={ref} {...props} />
})

interface CloseButtonProps extends ButtonProps {
  text?: string
}

const CloseButton = ({ text, ...props }: CloseButtonProps) => (
  <Button sx={{ py: 1, minHeight: 'auto' }} variant='contained' {...props}>
    {text}
  </Button>
)

export interface ModalDialogProps extends Omit<DialogProps, 'open'> {
  title?: string
  a11yTitle?: string
  body: React.ReactElement | string
  isOpened: boolean
  setIsOpened: (isOpened: boolean) => void
  closeButtonProps?: CloseButtonProps
  customActions?: React.ReactNode
  showCloseIcon?: boolean
  handleClose?: () => void
}

export const ModalDialog = ({
  title,
  a11yTitle,
  body,
  isOpened = false,
  setIsOpened,
  closeButtonProps,
  customActions,
  showCloseIcon = false,
  className,
  handleClose: handleCloseCustom,
  ...rest
}: ModalDialogProps) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  function handleClose() {
    handleCloseCustom?.() ?? setIsOpened(false)
  }

  return (
    <Dialog
      onClose={handleClose}
      open={isOpened}
      TransitionComponent={Transition}
      aria-labelledby='modal-title'
      fullScreen={isMobile}
      className={clsx(styles.root, className)}
      {...rest}
    >
      <DialogTitle
        component='div'
        onClick={isMobile ? handleClose : undefined}
        onTouchMove={isMobile ? handleClose : undefined}
      >
        {isMobile && <div className={styles.dragHandler} />}
        {title && (
          <Typography variant='h3' className={styles.title}>
            {title}
          </Typography>
        )}
        {showCloseIcon && !isMobile && (
          <IconButton onClick={handleClose} className={styles.closeIcon}>
            <IconClose fontSize='small' />
          </IconButton>
        )}
      </DialogTitle>
      {title && <Divider />}
      <DialogContent className={styles.content} id='alert-dialog-slide-description'>
        <div id='modal-title' className={styles.a11yTitle}>
          {a11yTitle ?? title}
        </div>
        {body}
      </DialogContent>

      {customActions ?? (
        <DialogActions sx={{ mx: 'auto' }}>
          <CloseButton onClick={handleClose} text='Close' {...closeButtonProps} />
        </DialogActions>
      )}
    </Dialog>
  )
}

export type ModalDialogType = typeof ModalDialog
