import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'

import clsx from 'clsx'

import styles from './InlineLabeledFormControl.module.scss'

export interface InlineLabeledFormControlProps extends React.ComponentProps<typeof FormControl> {
  error?: boolean
  focused?: boolean
  hovered?: boolean
  isValid?: boolean
  label?: string
  labelID?: string
}

export const InlineLabeledFormControl = ({
  children,
  error,
  focused,
  hovered,
  isValid,
  label,
  labelID,
  className,
  ...otherProps
}: InlineLabeledFormControlProps) => {
  return (
    <FormControl
      className={clsx({
        [styles.root]: true,
        className,
        [styles.valid]: !!isValid,
        [styles.hovered]: !!hovered,
        [styles.focused]: !!focused,
      })}
      {...otherProps}
    >
      {label && (
        <FormLabel id={labelID} className={label && styles.label}>
          {label}
        </FormLabel>
      )}
      <div
        className={clsx(styles.container, {
          [styles.containerNoLabel]: !label,
          [styles.errorContainer]: error,
        })}
      >
        {children}
      </div>
    </FormControl>
  )
}
