import { DEPLOY_ENV, DeployEnv } from 'utils/environment-variables'

export * from 'utils/environment-variables'

export const STARTING_PLATE = '/rp/life/digital'

export const DD_ALLOWED_TRACING_ORIGINS = [
  'https://assurance.com',
  'https://carinsurance.net',
  'https://healthinsurance.net',
  'https://homeinsurance.net',
  'https://lifeinsurance.net',
  'https://medicareplan.com',
  'https://nationalfamily.com',
].map(origin => (DEPLOY_ENV === DeployEnv.PRODUCTION ? origin : origin.replace('//', '//staging.')))
