import { SvgIcon, SvgIconProps } from 'icons'

/**
 * 'Arrow Button Right' icon from the Streamline Bold icon set
 * SEE: https://www.streamlinehq.com/icons/streamline-bold/arrows-diagrams/arrows/arrow-button-right
 */
export const ArrowButtonRightIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <g transform='matrix(1,0,0,1,0,0)'>
        <path d='M7.21,23.75l12.47-11h0a1.06,1.06,0,0,0,0-1.54L7.21.25A1,1,0,0,0,6.48,0a1,1,0,0,0-.69.36L4.24,2.21A1,1,0,0,0,4,3a1,1,0,0,0,.35.69l9.45,8.17a.27.27,0,0,1,0,.38L4.35,20.36a1,1,0,0,0-.35.69,1,1,0,0,0,.23.74l1.56,1.85a1,1,0,0,0,.69.36A1,1,0,0,0,7.21,23.75Z'></path>
      </g>
    </SvgIcon>
  )
}
