import { SvgIcon, SvgIconProps } from 'icons'

export const OpenLinkIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox='0 0 25 24' {...props}>
      <path
        d='M19.5 19H5.5V5H12.5V3H5.5C4.39 3 3.5 3.9 3.5 5V19C3.5 20.1 4.39 21 5.5 21H19.5C20.6 21 21.5 20.1 21.5 19V12H19.5V19ZM14.5 3V5H18.09L8.26 14.83L9.67 16.24L19.5 6.41V10H21.5V3H14.5Z'
        fill='white'
      />
    </SvgIcon>
  )
}
