export const requireEnvVar = (value?: string) => {
  if (typeof value === 'undefined')
    throw new Error('Environment variable is undefined — make sure to add it to your .env file.')

  return value
}

/**
 * Possible values for the `DEPLOY_ENV` environment variable.
 *
 * @example
 *   // Check if on a preview build
 *   DEPLOY_ENV === DeployEnv.PREVIEW
 */
export enum DeployEnv {
  DEVELOPMENT = 'development',
  PREVIEW = 'preview',
  INTEGRATION = 'integration',
  PRODUCTION = 'production',
}

/**
 * The current deploy environment (e.g. `"integration"`)
 *
 * Note that this is different than `NODE_ENV`, which defines the current
 * build/compile environment (e.g. `"development"`/`"production"`)
 *
 * See [Webpack docs on `NODE_ENV`](https://webpack.js.org/guides/production/#specify-the-mode)
 */

export const DEPLOY_ENV = requireEnvVar(process.env.REACT_APP_DEPLOY_ENV).toLowerCase() as DeployEnv

export const API_ORIGIN = requireEnvVar(process.env.REACT_APP_API_ORIGIN)
export const DD_APPLICATION_ID = requireEnvVar(process.env.REACT_APP_DD_APPLICATION_ID)
export const DD_CLIENT_TOKEN = requireEnvVar(process.env.REACT_APP_DD_CLIENT_TOKEN)
export const DD_SESSION_SAMPLE_RATE = parseInt(
  requireEnvVar(process.env.REACT_APP_DD_SESSION_SAMPLE_RATE),
  10,
)
export const DD_SESSION_REPLAY_SAMPLE_RATE = parseInt(
  requireEnvVar(process.env.REACT_APP_DD_SESSION_REPLAY_SAMPLE_RATE),
  10,
)
export const DD_SERVICE = requireEnvVar(process.env.REACT_APP_DD_SERVICE)
export const DD_VERSION = requireEnvVar(process.env.REACT_APP_DD_VERSION)
export const JORNAYA_CAMPAIGN_KEY = requireEnvVar(process.env.REACT_APP_JORNAYA_CAMPAIGN_KEY)

export const OX_CLIENT_ID = requireEnvVar(process.env.REACT_APP_OX_CLIENT_ID)
export const OX_HUMAN_RECOGNITION_TAG = requireEnvVar(
  process.env.REACT_APP_OX_HUMAN_RECOGNITION_TAG,
)
export const OX_UID = requireEnvVar(process.env.REACT_APP_OX_UID)

export const RECAPTCHA_ENTERPRISE_SITEKEY = requireEnvVar(
  process.env.REACT_APP_RECAPTCHA_ENTERPRISE_SITEKEY,
)
